import { Button, Typography } from "@material-ui/core";
import StaticMap from "components/GoogleStaticMap/StaticMap";
import React from "react";
import States from "us-states";
import { StateAccess } from "utils/models/formGenerator";
import {
  formatNumberAsCurrency,
  formatSSN,
  getStateLabelByState,
  removeCountryCodeAndBracesFromPhone
} from "../../utils/functions";
import { createOptionsForSelect, FormComponent, Model } from "../../utils/models/fields";
import AddressPreview from "../ZipCodeLookup/AddressPreview";
import PhoneFields, { HomePhone } from "./PhoneFields";
import SSNPreview from "./SSNPreview";
import { AdditionalIncome, Applicant } from "./types";
const fullName = (stateAccess: StateAccess) =>
  [
    stateAccess.get<Applicant>(["data", "info", "firstName"]),
    ...(stateAccess.get<Applicant>(["data", "info", "middleName"])
      ? [stateAccess.get<Applicant>(["data", "info", "middleName"])]
      : []),
    stateAccess.get<Applicant>(["data", "info", "lastName"]),
    stateAccess.get<Applicant>(["data", "info", "suffix"])
  ].join(" ");

const SSNStruct: FormComponent<Applicant> = {
  formComponent: "segment",
  name: "SSN",
  width: "full",
  entities: [
    {
      formComponent: "text-field",
      name: "socialSecurityNumber",
      label: "Social security number",
      required: true,
      width: "full",
      path: ["data", "info", "socialSecurityNumber"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "lastFourSSNDigits",
      label: "Last 4 SSN Digits",
      width: "1/3",
      description: "last four SSN digits",
      path: ["data", "info", "lastFourSSNDigits"],
      value: (stateAccess) => stateAccess.get<Applicant>(["data", "info", "lastFourSSNDigits"]),
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "formattedSSN",
      label: "Formatted SSN",
      width: "1/3",
      description: "SSN with hyphens",
      path: ["data", "info", "socialSecurityNumber"],
      value: (stateAccess) =>
        formatSSN(
          stateAccess.get<Applicant>(["data", "info", "socialSecurityNumber"])
        ),
      default: null
    }
  ]
};

export const personalInfo = (
  applicantType: "applicant" | "coApplicant"
): FormComponent<Applicant> => {
  return {
    formComponent: "segment",
    width: "full",
    entities: [
      {
        formComponent: "one-to-many-field",
        component: (stateAccess, mainstateAccess, renderSet) => {
          console.log(stateAccess.get(["data", "info", "warrantyOrPreApprovalOnly"]));
          return (
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Typography
                style={{
                  fontSize: "19px",
                  color: "rgb(37, 78, 110)",
                  margin: "5px 0px 10px",
                  fontWeight: "bold"
                }}
              >
                Personal Info
              </Typography>
              <Button
                style={
                  stateAccess.get(["data", "info", "warrantyOrPreApprovalOnly"])
                    ? {
                        background: "green"
                      }
                    : {}
                }
                onClick={() =>
                  stateAccess.set(
                    ["data", "info", "warrantyOrPreApprovalOnly"],
                    !stateAccess.get(["data", "info", "warrantyOrPreApprovalOnly"])
                  )
                }
                variant="contained"
                color="primary"
              >
                Warranty Only/Pre-Approval
              </Button>
            </div>
          );
        },
        name: "",
        path: [],
        width: "full",
        default: null
      },
      {
        formComponent: "name-field",
        name: "firstName",
        label: "First name",
        required: true,
        width: "1/4",
        path: ["data", "info", "firstName"],
        default: null
      },
      {
        formComponent: "name-field",
        name: "middleName",
        label: "Middle name",
        width: "1/4",
        path: ["data", "info", "middleName"],
        default: null
      },
      {
        formComponent: "name-field",
        name: "lastName",
        label: "Last name",
        required: true,
        width: "1/4",
        path: ["data", "info", "lastName"],
        default: null
      },
      {
        formComponent: "select-field",
        name: "Suffix",
        label: "Suffix",
        width: "1/4",
        path: ["data", "info", "suffix"],
        options: createOptionsForSelect({
          possibleValues: () => ["SR", "JR", "I", "II", "III", "IV", "V"],
          getOptionLabel: (x) => x,
          getSelectedOption: (x, y) => x === y
        }),
        default: null
      },
      {
        formComponent: "phone-field",
        name: "mobilePhone",
        label: "Mobile phone",
        width: "1/3",
        path: ["data", "info", "mobilePhone"],
        default: null
      },

      {
        formComponent: "one-to-many-field",
        struct: HomePhone,
        component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
          <PhoneFields stateAccess={stateAccess} renderSet={renderSet} />
        ),
        name: "Home phone",
        width: "1/3",
        path: ["data", "info", "homePhone"],
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "mobilePhoneWithoutCountryCode",
        label: "Mobile phone without country code",
        value: (stateAccess) => {
          const phoneNumber = stateAccess.get(["data", "info", "mobilePhone"]);
          return phoneNumber ? removeCountryCodeAndBracesFromPhone(phoneNumber) : "";
        },
        path: ["data", "info", "mobilePhoneWithoutCountryCode"],
        description: "Displays the mobile phone number without country code and ()",
        default: null
      },
      {
        formComponent: "one-to-many-field",
        component: (stateAccess, mainStateAccess, renderSet): JSX.Element => (
          <SSNPreview
            applicantType={applicantType}
            stateAccess={stateAccess}
            mainStateAccess={mainStateAccess}
            renderSet={renderSet}
          />
        ),
        struct: SSNStruct,
        name: "SSN",
        width: "1/3",
        path: [],
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "lastFourSSNDigits",
        label: "Last 4 SSN Digits",
        width: "1/3",
        description: "last four SSN digits",
        path: ["data", "info", "lastFourSSNDigits"],
        value: (stateAccess) => stateAccess.get<Applicant>(["data", "info", "lastFourSSNDigits"]),
        default: null
      },
      {
        formComponent: "date-with-age-field",
        name: "birthDate",
        label: "Date of birth",
        required: (stateAccess) => !stateAccess.get(["data", "info", "warrantyOrPreApprovalOnly"]),
        width: "1/2",
        path: ["data", "info", "birthDate"],
        disableRegion: "future",
        default: null
      },
      {
        formComponent: "email-field",
        name: "email",
        label: "Email",
        width: "1/2",
        path: ["data", "info", "email"],
        default: null
      },
      {
        formComponent: "radio-field",
        name: "usCitizen",
        label: "Is US citizen?",
        width: "1/3",
        path: ["data", "info", "usCitizen"],
        possibleValues: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ],
        default: "Yes",
        required: true
      },
      {
        formComponent: "radio-field",
        name: "maritalStatus",
        label: "Marital status",
        width: "2/3",
        path: ["data", "info", "maritalStatus"],
        possibleValues: [
          { label: "Married", value: "Married" },
          { label: "Not married", value: "Not married" }
        ],
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "hasDriversLicense",
        label: "X Has Drivers License?",
        value: (stateAccess) => {
          const licenseNumber = stateAccess.get<Applicant>(["data", "info", "driverLicenseNumber"]);

          return typeof licenseNumber === "string" && licenseNumber.length > 0;
        },
        description: "Displays X if there is a driver license number",
        path: ["data", "info", "hasDriversLicense"],
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "isUsCitizen",
        label: "X Is US citizen?",
        value: (stateAccess) => stateAccess.get<Applicant>(["data", "info", "usCitizen"]) === "Yes",
        path: ["data", "info", "isUsCitizen"],
        description: "Displays X the persone is an US citizen",
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "isNotUsCitizen",
        label: "X Is Not US citizen?",
        value: (stateAccess) => stateAccess.get<Applicant>(["data", "info", "usCitizen"]) === "No",
        path: ["data", "info", "isNotUsCitizen"],
        description: "Displays X the persone is NOT an US citizen",
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "married",
        label: "X Married?",
        value: (stateAccess) =>
          stateAccess.get<Applicant>(["data", "info", "maritalStatus"]) === "Married",
        description: "Displays X the persone is married",
        path: ["data", "info", "married"],
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "notMarried",
        label: "X Not married?",
        value: (stateAccess) =>
          stateAccess.get<Applicant>(["data", "info", "maritalStatus"]) === "Not married",
        description: "Displays X the persone is not married",
        path: ["data", "info", "notMarried"],
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "firstLastName",
        label: "First Name Last Name",
        value: (stateAccess) =>
          [
            stateAccess.get<Applicant>(["data", "info", "firstName"]),
            stateAccess.get<Applicant>(["data", "info", "lastName"])
          ].join(" "),
        path: ["data", "info", "firstLastName"],
        description: "Displays the first and the last name",
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "fullName",
        label: "Full name",
        value: (stateAccess) => fullName(stateAccess),
        path: ["data", "info", "fullName"],
        description: "Displays full name. Example: John M. Doe",
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "fullNameOrNA",
        label: "Full name or N/A",
        value: (stateAccess) =>
          stateAccess.get<Applicant>(["data", "info", "firstName"]) ? fullName(stateAccess) : "N/A",
        path: ["data", "info", "fullNameOrNA"],
        description: "Displays full name or N/A. Example: John M. Doe",
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "lastNameFirstNameMiddleName",
        label: "Last Name First Name Middle Name",
        value: (stateAccess) =>
          [
            stateAccess.get<Applicant>(["data", "info", "lastName"]),
            stateAccess.get<Applicant>(["data", "info", "firstName"]),
            ...(stateAccess.get<Applicant>(["data", "info", "middleName"])
              ? [stateAccess.get<Applicant>(["data", "info", "middleName"])[0] + "."]
              : [])
          ].join(" "),
        path: ["data", "info", "lastNameFirstNameMiddleName"],
        description: "Displays last name first name and middle name. Example: Doe John M.",
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "middleInitial",
        label: "Middle Initial",
        value: (stateAccess) =>
          stateAccess.get<Applicant>(["data", "info", "middleName"])
            ? stateAccess.get<Applicant>(["data", "info", "middleName"])[0] + "."
            : "",
        description: "Displays middle initial. Example: M.",
        path: ["data", "info", "middleInitial"],
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "initials",
        label: "Initials",
        value: (stateAccess) =>
          stateAccess
            .get<Applicant>(["data", "info", "firstName"])
            ?.substr(0, 1)
            .toUpperCase() +
          "." +
          stateAccess
            .get<Applicant>(["data", "info", "lastName"])
            ?.substr(0, 1)
            .toUpperCase(),
        description: "Displays initials. Example: J.D.",
        path: ["data", "info", "initials"],
        default: null
      }
    ]
  };
};

export const getCurrentAddressStruct = (zipCodeSearch: () => void): FormComponent<Applicant> => {
  return {
    formComponent: "segment",
    width: "full",
    name: "Current Address",
    entities: [
      {
        formComponent: "segment",
        width: "2/3",
        entities: [
          {
            formComponent: "text-field",
            name: "currentAddressNumber",
            label: "#",
            path: ["data", "info", "currentAddressNumber"],
            width: "1/4",
            default: null
          },
          {
            formComponent: "address-field",
            name: "currentAddress",
            required: true,
            label: "Address",
            path: ["data", "info", "currentAddress"],
            width: "3/4",
            default: null
          }
        ]
      },

      {
        formComponent: "zip-code-field",
        name: "zipCode",
        label: "Zip code",
        zipCodeSearch: zipCodeSearch,
        path: ["data", "info", "currentZipCode"],
        width: "1/3",
        default: null
      },
      {
        formComponent: "hidden-field",
        name: "currentCountry",
        label: "Country",
        width: "1/4",
        path: ["data", "info", "currentCountry"],
        default: "USA"
      },
      {
        formComponent: "select-field",
        name: "state",
        label: "State",
        width: "1/3",
        path: ["data", "info", "currentState"],
        autoSelect: true,
        options: createOptionsForSelect({
          possibleValues: () => Object.keys(States),
          getOptionLabel: (x) => getStateLabelByState(x),
          getSelectedOption: (x, y) => x === y
        }),
        default: null
      },
      {
        formComponent: "select-field",
        name: "county",
        label: "County",
        width: "1/3",
        autoSelect: true,
        path: ["data", "info", "currentCounty"],
        options: createOptionsForSelect({
          possibleValues: (stateAccess: StateAccess) =>
            States[stateAccess.get<Applicant>(["data", "info", "currentState"]) as string]
              ?.counties,
          getOptionLabel: (x) => x ?? " ",
          getSelectedOption: (x, y) => x === y
        }),
        default: null
      },
      {
        formComponent: "city-field",
        name: "city",
        label: "City",
        minLength: 3,
        path: ["data", "info", "currentCity"],
        width: "1/3",
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "currentStatePrefix",
        label: "State prefix",
        value: (stateAccess) => {
          const info: Applicant["data"]["info"] = stateAccess.get<Applicant>(["data", "info"]);

          return States[info?.currentState]?.prefix ?? "";
        },
        description: "Displays current state prefix. Example: WI",
        path: ["data", "info", "currentStatePrefix"],
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "fullAddressWithLocation",
        label: "Full Address with Location",
        value: (stateAccess) => {
          const info: Applicant["data"]["info"] = stateAccess.get<Applicant>(["data", "info"]);

          return [
            [info?.currentAddressNumber, info?.currentAddress].filter((x) => x).join(" "),
            info?.currentCity,
            info?.currentCounty,
            [States[info?.currentState]?.prefix, info?.currentZipCode].filter((x) => x).join(" ")
          ]
            .filter((x) => x)
            .join(", ");
        },
        path: ["data", "info", "fullAddressWithLocation"],
        description:
          "Displays the address on one line with location.<br/>Example: 6370 Concord Blvd S, Heights, La Crosse, WI",
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "fullAddressWithLocationNoCounty",
        label: "Full Address with Location (No county)",
        value: (stateAccess) => {
          const info: Applicant["data"]["info"] = stateAccess.get<Applicant>(["data", "info"]);

          return [
            [info?.currentAddressNumber, info?.currentAddress].filter((x) => x).join(" "),
            info?.currentCity,
            [States[info?.currentState]?.prefix, info?.currentZipCode].filter((x) => x).join(" ")
          ]
            .filter((x) => x)
            .join(", ");
        },
        path: ["data", "info", "fullAddressWithLocationNoCounty"],
        description:
          "Displays the address on one line with location.<br/>Example: 6370 Concord Blvd S, Heights, WI",
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "currentCityStateZip",
        label: "City state zip",
        value: (stateAccess) => {
          const info: Applicant["data"]["info"] = stateAccess.get<Applicant>(["data", "info"]);

          return info?.currentCity && info?.currentZipCode
            ? [
                info?.currentCity + ",",
                States[info?.currentState]?.prefix ?? "",
                info?.currentZipCode
              ].join(" ")
            : "";
        },
        path: ["data", "info", "currentCityStateZip"],
        description: "Displays the city,state prefix and zip code. Example: Onalaska WI 54650",
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "fullAddress",
        label: "Full Address",
        value: (stateAccess) => {
          const info: Applicant["data"]["info"] = stateAccess.get<Applicant>(["data", "info"]);
          return [info?.currentAddressNumber, info?.currentAddress].join(" ");
        },
        description: "Displays the address on one line . Example: 6370 Concord Blvd S",
        path: ["data", "info", "fullAddress"],
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "fullLocation",
        label: "Full Location",
        value: (stateAccess) => {
          const info: Applicant["data"]["info"] = stateAccess.get<Applicant>(["data", "info"]);

          return [
            info?.currentCity,
            info?.currentCounty,
            [States[info?.currentState]?.prefix, info?.currentZipCode].filter((x) => x).join(" ")
          ]
            .filter((x) => x)
            .join(", ");
        },
        description: "Displays the location on one line. Example:  Heights, La Crosse, WI",
        path: ["data", "info", "fullLocation"],
        default: null
      },
      {
        formComponent: "virtual-field",
        name: "cityStateZip",
        label: "City State Zip",
        value: (stateAccess) => {
          const info: Applicant["data"]["info"] = stateAccess.get<Applicant>(["data", "info"]);

          return [
            info?.currentCity,
            [States[info?.currentState]?.prefix, info?.currentZipCode].filter((x) => x).join(" ")
          ]
            .filter((x) => x)
            .join(", ");
        },
        description: "Displays the location on one line. Example:  Heights, WI 54650",
        path: ["data", "info", "cityStateZip"],
        default: null
      }
    ]
  };
};

const timeAtAddress: FormComponent<Applicant> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "months-field",
      name: "yearsAtCurrentAddress",
      label: "Years at address",
      path: ["data", "info", "yearsAtCurrentAddress"],
      width: "1/4",
      default: null
    },
    {
      formComponent: "months-field",
      name: "monthsAtCurrentAddress",
      label: "Months at address",
      path: ["data", "info", "monthsAtCurrentAddress"],
      width: "1/4",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "formattedTimeAtAddress",
      label: "FormattedTimeAtAddress",
      value: (stateAccess) => {
        const months = stateAccess.get(["data", "info", "monthsAtCurrentAddress"]);
        const years = stateAccess.get(["data", "info", "yearsAtCurrentAddress"]);
        let yearsText = "";

        if (years > 0) {
          if (years === 1) {
            yearsText = `1 year`;
          } else {
            yearsText = `${years} years`;
          }
        }
        let monthsText = "";

        if (months > 0) {
          if (months === 1) {
            monthsText = `1 month`;
          } else {
            monthsText = `${months} months`;
          }
        }
        return `${yearsText} ${monthsText}`;
      },
      description: "Displays formatted time at address",
      path: ["data", "info", "formattedTimeAtAddress"],
      default: null
    }
  ]
};
export const getPreviousAddressStruct = (zipCodeSearch: () => void): FormComponent<Applicant> => ({
  formComponent: "segment",
  width: "full",
  name: "Previous Address",
  entities: [
    {
      formComponent: "segment",
      width: "2/3",
      entities: [
        {
          formComponent: "text-field",
          name: "previousAddressNumber",
          label: "#",
          path: ["data", "info", "previousAddressNumber"],
          width: "1/4",
          default: null
        },
        {
          formComponent: "address-field",
          name: "previousAddress",
          required: false,
          label: "Address",
          path: ["data", "info", "previousAddress"],
          width: "3/4",
          default: null
        },
        {
          formComponent: "virtual-field",
          name: "previousFullAddress",
          label: "Previous Full Address",
          value: (stateAccess) => {
            const info: Applicant["data"]["info"] = stateAccess.get<Applicant>(["data", "info"]);

            return [info?.previousAddressNumber, info?.previousAddress].join(" ");
          },
          description: "Displays the previous address on one line . Example: 6370 Concord Blvd S",
          path: ["data", "info", "previousFullAddress"],
          default: null
        }
      ]
    },

    {
      formComponent: "zip-code-field",
      name: "zipCode",
      label: "Zip code",
      zipCodeSearch: zipCodeSearch,
      path: ["data", "info", "previousZipCode"],
      width: "1/3",
      default: null
    },
    {
      formComponent: "hidden-field",
      name: "previousCountry",
      label: "Country",
      width: "1/4",
      path: ["data", "info", "previousCountry"],
      default: "USA"
    },
    {
      formComponent: "select-field",
      name: "state",
      label: "State",
      width: "1/4",
      path: ["data", "info", "previousState"],
      options: createOptionsForSelect({
        possibleValues: () => Object.keys(States),
        getOptionLabel: (x) => getStateLabelByState(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "previousStatePrefix",
      label: "Previous state prefix",
      value: (stateAccess) => {
        const info: Applicant["data"]["info"] = stateAccess.get<Applicant>(["data", "info"]);

        return States[info?.previousState]?.prefix ?? "";
      },
      description: "Displays previous state prefix. Example: WI",
      path: ["data", "info", "previousStatePrefix"],
      default: null
    },
    {
      formComponent: "select-field",
      name: "county",
      label: "County",
      width: "1/4",
      path: ["data", "info", "previousCounty"],
      options: createOptionsForSelect({
        possibleValues: (stateAccess: StateAccess) =>
          States[stateAccess.get<Applicant>(["data", "info", "previousState"]) as string]?.counties,
        getOptionLabel: (x) => x ?? " ",
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "city-field",
      name: "city",
      label: "City",
      minLength: 3,
      path: ["data", "info", "previousCity"],
      width: "1/4",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "fullPreviousAddressWithLocation",
      label: "Full Previous Address with Location",
      value: (stateAccess) => {
        const info: Applicant["data"]["info"] = stateAccess.get<Applicant>(["data", "info"]);

        return [
          [info?.previousAddressNumber, info?.previousAddress].filter((x) => x).join(" "),
          info?.previousCity,
          info?.previousCounty,
          [States[info?.previousState]?.prefix, info?.previousZipCode].filter((x) => x).join(" ")
        ]
          .filter((x) => x)
          .join(", ");
      },
      path: ["data", "info", "fullPreviousAddressWithLocation"],
      description:
        "Displays the  previous address on one line with location.<br/>Example: 6370 Concord Blvd S, Heights, La Crosse, WI",
      default: null
    }
  ]
});
const timeAtPreviousAddress: FormComponent<Applicant> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "months-field",
      name: "yearsAtPreviousAddress",
      label: "Years at address",
      path: ["data", "info", "yearsAtPreviousAddress"],
      width: "1/4",
      default: null
    },
    {
      formComponent: "months-field",
      name: "monthsAtPreviousAddress",
      label: "Months at address",
      path: ["data", "info", "monthsAtPreviousAddress"],
      width: "1/4",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "formattedPreviousTimeAtAddress",
      label: "FormattedPreviousTimeAtAddress",
      value: (stateAccess) => {
        const months = stateAccess.get(["data", "info", "monthsAtPreviousAddress"]);
        const years = stateAccess.get(["data", "info", "yearsAtPreviousAddress"]);
        let yearsText = "";

        if (years > 0) {
          if (years === 1) {
            yearsText = `1 year`;
          } else {
            yearsText = `${years} years`;
          }
        }
        let monthsText = "";

        if (months > 0) {
          if (months === 1) {
            monthsText = `1 month`;
          } else {
            monthsText = `${months} months`;
          }
        }
        return `${yearsText} ${monthsText}`;
      },
      description: "Displays formatted time at previous address",
      path: ["data", "info", "formattedPreviousTimeAtAddress"],
      default: null
    }
  ]
};

const driverLicense: FormComponent<Applicant> = {
  formComponent: "segment",
  width: "full",
  name: "Driver License",
  entities: [
    {
      formComponent: "virtual-field",
      name: "licenseStateLicenseNumberExpirationOrN/A",
      label: "License state, number, expiration Or N/A",
      value: (stateAccess) => {
        const info: Applicant["data"]["info"] = stateAccess.get<Applicant>(["data", "info"]);
        const licenseNumber = info?.driverLicenseNumber;
        const state = States[info?.drivingLicenseState]?.prefix;
        const expiration = info?.driverLicenseExpires;

        return info
          ? [
              state ?? "",
              licenseNumber ?? "",
              expiration ? new Date(expiration)?.toLocaleDateString("en-US") : ""
            ].join(" ")
          : "N/A";
      },
      description: "Displays applicant license state prefix, number and expiration or N/A",
      path: ["data", "info", "licenseStateLicenseNumberExpirationOrN/A"],
      default: null
    },
    {
      formComponent: "license-number-field",
      name: "licenseNumber",
      label: "License number",
      width: "1/4",
      path: ["data", "info", "driverLicenseNumber"],
      default: null
    },
    {
      formComponent: "segment",
      name: "License number digits",
      show: () => false,
      entities: new Array(13).fill(0).map(
        (x, index) =>
          ({
            formComponent: "virtual-field",
            name: `licenseNumber${index}`,
            label: index.toString(),
            value: (stateAccess) =>
              stateAccess.get<Applicant>(["data", "info", "driverLicenseNumber"])?.[index] ?? "",
            path: ["data", "info", "driverLicenseNumberDigits", index],
            width: "hidden",
            default: null
          } as FormComponent<Applicant>)
      )
    },
    {
      formComponent: "select-field",
      name: "state",
      label: "State",
      width: "1/4",
      path: ["data", "info", "drivingLicenseState"],
      options: createOptionsForSelect({
        possibleValues: () => Object.keys(States),
        getOptionLabel: (x) => getStateLabelByState(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "drivingLicenseStatePrefix",
      label: "State prefix",
      value: (stateAccess) => {
        const info: Applicant["data"]["info"] = stateAccess.get<Applicant>(["data", "info"]);

        return States[info?.drivingLicenseState]?.prefix ?? "";
      },
      description: "Displays driver license state prefix. Example: WI",
      path: ["data", "info", "drivingLicenseStatePrefix"],
      default: null
    },
    {
      formComponent: "date-field",
      name: "licenseIssueDate",
      label: "License Issue date",
      width: "1/4",
      path: ["data", "info", "driverLicenseIssued"],
      disableRegion: "future",
      default: null
    },
    {
      formComponent: "date-field",
      name: "licenseExpiryDate",
      label: "License Expiry date",
      width: "1/4",
      disableRegion: "past",
      path: ["data", "info", "driverLicenseExpires"],
      default: null
    }
  ]
};

const employment: FormComponent<Applicant> = {
  formComponent: "segment",
  width: "full",
  name: "Employment",
  entities: [
    {
      formComponent: "radio-field",
      name: "employmentStatus",
      label: "Employment status",
      width: "full",
      path: ["data", "info", "employmentStatus"],
      possibleValues: [
        { label: "Employee", value: "Employee" },
        { label: "Business owner", value: "Business owner" },
        { label: "Retired", value: "Retired" }
      ],
      default: null
    },
    {
      formComponent: "text-field",
      name: "sourceOfRetirementIncome",
      label: "Source of Retirement Income",
      width: "1/2",
      path: ["data", "info", "sourceOfRetirementIncome"],
      show: (stateAccess: StateAccess) =>
        stateAccess.get<Applicant>(["data", "info", "employmentStatus"]) === "Retired",
      default: null
    },
    {
      formComponent: "currency-field",
      name: "Retirement Income",
      label: "Retirement Income",
      width: "1/2",
      path: ["data", "info", "retirementIncome"],
      show: (stateAccess: StateAccess) =>
        stateAccess.get<Applicant>(["data", "info", "employmentStatus"]) === "Retired",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "Displays Income",
      label: "Displays Income",
      value: (stateAccess) =>
        formatNumberAsCurrency(
          stateAccess.get<Applicant>(["data", "info", "employmentStatus"]) === "Retired"
            ? stateAccess.get<Applicant>(["data", "info", "retirementIncome"])
            : stateAccess.get<Applicant>(["data", "info", "monthlyIncome"])
        ) ?? "",
      path: ["data", "info", "monthlyIncome"],
      description: "Displays income based on employment status",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "Displays Income Source",
      label: "Displays Income Source",
      value: (stateAccess) =>
        stateAccess.get<Applicant>(["data", "info", "employmentStatus"]) === "Retired"
          ? stateAccess.get<Applicant>(["data", "info", "sourceOfRetirementIncome"])
          : stateAccess.get<Applicant>(["data", "info", "employer"]),
      path: ["data", "info", "employer"],
      description: "Displays source of income based on employment status",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "isEmployee",
      label: "X Is Employee?",
      value: (stateAccess) =>
        stateAccess.get<Applicant>(["data", "info", "employmentStatus"]) === "Employee",
      path: ["data", "info", "employmentStatus"],
      description: "Displays X if the person is employee",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "isBuinessOwner",
      label: "X Is Business Owner?",
      value: (stateAccess) =>
        stateAccess.get<Applicant>(["data", "info", "employmentStatus"]) === "Business owner",
      path: ["data", "info", "employmentStatus"],
      description: "Displays X if the person is business owner",
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "isRetired",
      label: "X Is Retired?",
      value: (stateAccess) =>
        stateAccess.get<Applicant>(["data", "info", "employmentStatus"]) === "Retired",
      path: ["data", "info", "employmentStatus"],
      description: "Displays X if the person is retired",
      default: null
    },
    {
      formComponent: "text-field",
      name: "employer",
      label: "Employer",
      width: "1/3",
      show: (stateAccess: StateAccess) =>
        stateAccess.get<Applicant>(["data", "info", "employmentStatus"]) === "User",
      path: ["data", "info", "employer"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "typeOfBusiness",
      label: "Type of business",
      width: "1/3",
      show: (stateAccess: StateAccess) =>
        stateAccess.get<Applicant>(["data", "info", "employmentStatus"]) === "Business owner",
      path: ["data", "info", "typeOfBusiness"],
      default: null
    },
    {
      formComponent: "phone-field",
      name: "businessPhone",
      label: "Business phone",
      width: "full",
      path: ["data", "info", "businessPhone"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "employer",
      label: "Employer",
      width: "1/3",
      path: ["data", "info", "employer"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "jobOccupation",
      label: "Job occupation",
      width: "1/3",
      path: ["data", "info", "jobOccupation"],
      default: null
    },
    {
      formComponent: "months-field",
      name: "yearsAtCurrentJob",
      label: "Years",
      path: ["data", "info", "yearsAtCurrentJob"],
      width: "1/6",
      min: 0,
      default: null
    },
    {
      formComponent: "months-field",
      name: "monthsAtCurrentJob",
      label: "Months",
      path: ["data", "info", "monthsAtCurrentJob"],
      width: "1/6",
      min: 0,
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "formattedTimeAtJob",
      label: "formattedTimeAtJob",
      value: (stateAccess) => {
        const months = stateAccess.get(["data", "info", "monthsAtCurrentJob"]);
        const years = stateAccess.get(["data", "info", "yearsAtCurrentJob"]);
        let yearsText = "";

        if (years > 0) {
          if (years === 1) {
            yearsText = `1 year`;
          } else {
            yearsText = `${years} years`;
          }
        }
        let monthsText = "";

        if (months > 0) {
          if (months === 1) {
            monthsText = `1 month`;
          } else {
            monthsText = `${months} months`;
          }
        }
        return `${yearsText} ${monthsText}`;
      },
      description: "Displays formatted time at job",
      path: ["data", "info", "formattedTimeAtJob"],
      default: null
    },

    {
      formComponent: "currency-field",
      name: "annualIncome",
      label: "Annual income",
      path: ["data", "info", "annualIncome"],
      width: "1/3",
      default: null,
      min: 0
    },

    {
      formComponent: "currency-field",
      name: "monthlyIncome",
      label: "Monthly income",
      path: ["data", "info", "monthlyIncome"],
      width: "1/3",
      default: null,
      min: 0
    },

    {
      formComponent: "text-field",
      name: "previousEmployer",
      label: "Previous Employer",
      width: "1/3",
      path: ["data", "info", "previousEmployer"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "previousOccupation",
      label: "Previous job occupation",
      width: "1/3",
      path: ["data", "info", "previousOccupation"],
      default: null
    },
    {
      formComponent: "months-field",
      name: "yearsAtPreviousJob",
      label: "Years",
      path: ["data", "info", "yearsAtPreviousJob"],
      width: "1/6",
      default: null,
      min: 0
    },
    {
      formComponent: "months-field",
      name: "monthsAtPreviousJob",
      label: "Months",
      path: ["data", "info", "monthsAtPreviousJob"],
      width: "1/6",
      default: null,
      min: 0
    },
    {
      formComponent: "virtual-field",
      name: "formattedTimeAtPrevJob",
      label: "formattedTimeAtPrevJob",
      value: (stateAccess) => {
        const months = stateAccess.get(["data", "info", "monthsAtPreviousJob"]);
        const years = stateAccess.get(["data", "info", "yearsAtPreviousJob"]);
        let yearsText = "";

        if (years > 0) {
          if (years === 1) {
            yearsText = `1 year`;
          } else {
            yearsText = `${years} years`;
          }
        }
        let monthsText = "";

        if (months > 0) {
          if (months === 1) {
            monthsText = `1 month`;
          } else {
            monthsText = `${months} months`;
          }
        }
        return `${yearsText} ${monthsText}`;
      },
      description: "Displays formatted time at prevoious job",
      path: ["data", "info", "formattedTimeAtPrevJob"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "sourceOfAdditionalIncome",
      label: "Source of Additional Income",
      width: "full",
      value: (stateAccess) => {
        const incomes: AdditionalIncome[] = stateAccess.get<Applicant>([
          "data",
          "info",
          "additionalIncomes"
        ]);

        return incomes?.length > 0
          ? incomes
              ?.filter((x) => x.source)
              ?.map((x) => x.source)
              .join(", ")
          : "";
      },
      description: "Displays source of additional income",
      path: ["data", "info", "sourceOfAdditionalIncome"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "additionalMonthlyIncome",
      label: "Additional Monthly Income",
      path: ["data", "info", "additionalIncome"],
      width: "1/3",
      default: null,
      value: (stateAccess) => {
        const incomes: AdditionalIncome[] = stateAccess.get<Applicant>([
          "data",
          "info",
          "additionalIncomes"
        ]);

        return incomes?.length > 0
          ? formatNumberAsCurrency(
              incomes
                .filter((x) => x.value)
                .map((x) => x.value)
                .reduce((a, b) => (a as number) + (b as number), 0)
            ) || ""
          : "";
      },
      description: "Displays additional monthly income"
    },
    // {
    //   formComponent: "virtual-field",
    //   name: "formattedTimeAtPrevJob",
    //   label: "formattedTimeAtPrevJob",
    //   value: (stateAccess) => {
    //     const months = stateAccess.get(["data", "info", "monthsAtPreviousJob"]);
    //     const years = stateAccess.get(["data", "info", "yearsAtPreviousJob"]);
    //     let yearsText = "";

    //     if (years > 0) {
    //       if (years === 1) {
    //         yearsText = `1 year`;
    //       } else {
    //         yearsText = `${years} years`;
    //       }
    //     }
    //     let monthsText = "";

    //     if (months > 0) {
    //       if (months === 1) {
    //         monthsText = `1 month`;
    //       } else {
    //         monthsText = `${months} months`;
    //       }
    //     }
    //     return `${yearsText} ${monthsText}`;
    //   },
    //   description: "Displays formatted time at prevoious job",
    //   path: ["data", "info", "formattedTimeAtPrevJob"],
    //   default: null
    // },
    {
      formComponent: "list-model",
      name: "Additional Income",
      entity: {
        formComponent: "segment",
        entities: [
          {
            formComponent: "currency-field",
            name: "value",
            label: "Value",
            path: ["value"],
            width: "1/2",
            default: null
          },
          {
            formComponent: "text-field",
            name: "source",
            label: "Source",
            path: ["source"],
            required: true,
            width: "1/2",
            default: null
          }
        ] as FormComponent<AdditionalIncome>[]
      },
      width: "full",
      required: false,
      path: ["data", "info", "additionalIncomes"],
      renderOrder: "desc"
    }
  ]
};

const propertyInformation: FormComponent<Applicant> = {
  formComponent: "segment",
  width: "full",
  name: "Property information",
  entities: [
    {
      formComponent: "radio-field",
      name: "propertyOwnership",
      label: "Property ownership",
      width: "full",
      path: ["data", "info", "propertyOwnership"],
      possibleValues: [
        { label: "Owner", value: "Owner" },
        { label: "Rent", value: "Rent" },
        { label: "Family", value: "Family" },
        { label: "Other", value: "Other" }
      ],
      default: "Owner"
    },
    {
      formComponent: "virtual-field",
      name: "xOwner",
      label: "X Owner?",
      value: (stateAccess) =>
        stateAccess.get<Applicant>(["data", "info", "propertyOwnership"]) === "Owner",
      description: "Displays X if the persone is owner of the property",
      path: ["data", "info", "xOwner"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "xRent",
      label: "X Rent?",
      value: (stateAccess) =>
        stateAccess.get<Applicant>(["data", "info", "propertyOwnership"]) === "Rent",
      description: "Displays X if the persone is Renting the property",
      path: ["data", "info", "xRent"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "xFamily",
      label: "X Family?",
      value: (stateAccess) =>
        stateAccess.get<Applicant>(["data", "info", "propertyOwnership"]) === "Family",
      description: "Displays X if the persone is living in the family property",
      path: ["data", "info", "xFamily"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "xOther",
      label: "X Other?",
      value: (stateAccess) =>
        stateAccess.get<Applicant>(["data", "info", "propertyOwnership"]) === "Other",
      description: "Displays X if the persones ownership is set to other",
      path: ["data", "info", "xOther"],
      default: null
    },
    {
      formComponent: "virtual-field",
      name: "mortgageOrRent",
      label: "Morgage or Rent",
      value: (stateAccess) => {
        const propertyOwnership = stateAccess.get<Applicant>(["data", "info", "propertyOwnership"]);

        if (propertyOwnership?.toLowerCase() === "rent") {
          return (
            formatNumberAsCurrency(
              stateAccess.get<Applicant>(["data", "info", "rentPerMonth"])
            ) ?? ""
          );
        }
        if (propertyOwnership?.toLowerCase() === "owner") {
          return (
            formatNumberAsCurrency(
              stateAccess.get<Applicant>(["data", "info", "mortgagePerMonth"])
            ) ?? ""
          );
        }
        return "";
      },
      description: "Displays morgage or rent value",
      path: ["data", "info", "mortgageOrRent"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "mortgagePerMonth",
      label: "Mortgage per month",
      width: "1/2",
      show: (stateAccess: StateAccess) =>
        stateAccess.get<Applicant>(["data", "info", "propertyOwnership"]) !== "Rent",
      path: ["data", "info", "mortgagePerMonth"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "rentPerMonth",
      label: "Rent per month",
      width: "1/2",
      show: (stateAccess: StateAccess) =>
        stateAccess.get<Applicant>(["data", "info", "propertyOwnership"]) === "Rent",
      path: ["data", "info", "rentPerMonth"],
      default: null
    }
  ]
};

export const applicantStruct: Model<Applicant> = {
  formComponent: "model",
  schema: "new_applicant",
  name: "applicant",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      name: "Applicant",
      entities: [
        {
          formComponent: "segment",
          entities: [
            personalInfo("applicant"),
            {
              formComponent: "one-to-many-field",
              component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
                <AddressPreview
                  type="current"
                  stateAccess={stateAccess}
                  renderSet={renderSet}
                  getStruct={getCurrentAddressStruct}
                  zipCodePath={["data", "info", "currentZipCode"]}
                  addressSetter={(stateAccess, address) =>
                    stateAccess.set<Applicant>(["data", "info"], {
                      ...stateAccess.get<Applicant>(["data", "info"]),
                      currentState: address.state,
                      currentCounty: address.county,
                      currentCity: address.city
                    })
                  }
                />
              ),
              name: "Address preview",
              width: "full",
              path: [],
              default: null,
              struct: getCurrentAddressStruct(() => undefined)
            },
            timeAtAddress,
            {
              formComponent: "one-to-many-field",
              path: [],
              name: "Map",
              show: (stateAccess) => stateAccess.get(["data", "info", "coordinates"]),
              component: (stateAccess) => {
                const applicant: Applicant = stateAccess.get([]);
                const streetNumber = applicant?.data?.info?.currentAddressNumber;
                const street = applicant?.data?.info?.currentAddress;
                const state = applicant?.data?.info?.currentState;
                const city = applicant?.data?.info?.currentCity;
                const coordinates = applicant?.data?.info?.coordinates as {
                  lat: number;
                  lon: number;
                };

                return (
                  <StaticMap
                    type="applicant"
                    street={`${streetNumber},${street}`}
                    state={state}
                    city={city}
                    coordinates={coordinates}
                  />
                );
              },
              width: "full"
            },
            propertyInformation,
            {
              formComponent: "one-to-many-field",
              component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
                <AddressPreview
                  type="previous"
                  stateAccess={stateAccess}
                  renderSet={renderSet}
                  getStruct={getPreviousAddressStruct}
                  zipCodePath={["data", "info", "previousZipCode"]}
                  addressSetter={(stateAccess, address) =>
                    stateAccess.set<Applicant>(["data", "info"], {
                      ...stateAccess.get<Applicant>(["data", "info"]),
                      previousState: address.state,
                      previousCounty: address.county,
                      previousCity: address.city
                    })
                  }
                />
              ),
              name: "Previous address preview",
              width: "full",
              path: null,
              default: null,
              struct: getPreviousAddressStruct(() => undefined)
            },
            timeAtPreviousAddress
          ]
        },
        {
          formComponent: "segment",
          entities: [driverLicense, employment]
        }
      ]
    }
  ]
};

export const coApplicantStruct = (
  addressSegment: FormComponent<Applicant>,
  previousAddressSegment: FormComponent<Applicant>
): Model<Applicant> => ({
  formComponent: "model",
  schema: "new_applicant",
  name: "applicant",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      name: "Co Applicant",
      entities: [
        {
          formComponent: "segment",
          entities: [
            personalInfo("coApplicant"),
            addressSegment,
            {
              formComponent: "one-to-many-field",
              component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
                <AddressPreview
                  type="current"
                  stateAccess={stateAccess}
                  renderSet={renderSet}
                  getStruct={getCurrentAddressStruct}
                  zipCodePath={["data", "info", "currentZipCode"]}
                  addressSetter={(stateAccess, address) =>
                    stateAccess.set<Applicant>(["data", "info"], {
                      ...stateAccess.get<Applicant>(["data", "info"]),
                      currentState: address.state,
                      currentCounty: address.county,
                      currentCity: address.city
                    })
                  }
                />
              ),
              name: "Address preview",
              width: "full",
              path: [] as any,
              default: {},
              struct: getCurrentAddressStruct(() => undefined)
            },
            timeAtAddress,
            {
              formComponent: "one-to-many-field",
              path: [],
              name: "Map",
              show: (stateAccess) => stateAccess.get(["data", "info", "coordinates"]),
              component: (stateAccess) => {
                const applicant: Applicant = stateAccess.get([]);
                const streetNumber = applicant?.data?.info?.currentAddressNumber;
                const street = applicant?.data?.info?.currentAddress;
                const state = applicant?.data?.info?.currentState;
                const city = applicant?.data?.info?.currentCity;
                const coordinates = applicant?.data?.info?.coordinates as {
                  lat: number;
                  lon: number;
                };

                return (
                  <StaticMap
                    type="applicant"
                    street={`${streetNumber},${street}`}
                    state={state}
                    city={city}
                    coordinates={coordinates}
                  />
                );
              },
              width: "full"
            },
            propertyInformation,
            previousAddressSegment,
            {
              formComponent: "one-to-many-field",
              component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
                <AddressPreview
                  type="previous"
                  stateAccess={stateAccess}
                  renderSet={renderSet}
                  getStruct={getPreviousAddressStruct}
                  zipCodePath={["data", "info", "currentZipCode"]}
                  addressSetter={(stateAccess, address) =>
                    stateAccess.set<Applicant>(["data", "info"], {
                      ...stateAccess.get<Applicant>(["data", "info"]),
                      previousState: address.state,
                      previousCounty: address.county,
                      previousCity: address.city
                    })
                  }
                />
              ),
              name: "Previous address preview",
              width: "full",
              path: [] as any,
              default: {},
              struct: getPreviousAddressStruct(() => undefined)
            },
            timeAtPreviousAddress
          ]
        },
        {
          formComponent: "segment",
          entities: [driverLicense, employment]
        }
      ]
    }
  ]
});
