import { performLocalCheck } from "components/ZipCodeLookup/AddressPreview";
import States from "us-states";
import {
  capitalize,
  capitalizeFirstLetterLowerCaseOthers,
  getStateByPrefix,
  getStateLabelByState
} from "utils/functions";
import { FormComponent, createOptionsForSelect } from "utils/models/fields";
import { StateAccess } from "utils/models/formGenerator";
import { ParsedDealData } from "./AddDealByDocument";
import { AdditionalIncome } from "components/Applicants/types";

const zipCodeSearch = (type: "applicant" | "coApplicant", time: "current" | "previous") => (
  stateAccess: StateAccess
) => {
  const ZIP = stateAccess.get([type, `${time}ZipCode`]);
  if (ZIP) {
    performLocalCheck(ZIP).then((result) => {
      const prevApplicant = stateAccess.get(["applicant"]) ?? {};
      stateAccess.set([type], {
        ...prevApplicant,
        [`${time}State`]:
          getStateByPrefix(result?.usps?.CityStateLookupResponse?.ZipCode?.State ?? "") ?? null,
        [`${time}StatePrefix`]: result?.usps?.CityStateLookupResponse?.ZipCode?.State ?? null,
        [`${time}County`]:
          result?.google?.[0]?.county?.long_name?.replace(" County", "") ??
          result?.local?.county?.replace(" County", "") ??
          null,

        [`${time}City`]:
          capitalizeFirstLetterLowerCaseOthers(
            result?.usps?.CityStateLookupResponse?.ZipCode?.City
          ) ?? null
      });
    });
  } else {
    alert(`Please enter zip code!`);
  }
};

const addressStruct = (type: "applicant" | "coApplicant", time: "current" | "previous") => {
  return {
    formComponent: "segment",
    name: `${capitalize(time)} Address`,
    width: "full",
    entities: [
      {
        formComponent: "segment",
        width: 6,
        entities: [
          {
            formComponent: "text-field",
            name: time + "AddressNumber",
            label: "#",
            path: [type, time + "AddressNumber"],
            width: "1/4",
            default: null
          },
          {
            formComponent: "address-field",
            name: time + "Address",
            label: "Address",
            path: [type, time + "Address"],
            width: "3/4",
            default: null
          }
        ]
      },
      {
        formComponent: "zip-code-field",
        name: "zipCode",
        label: "Zip code",
        zipCodeSearch: zipCodeSearch(type, time),
        path: [type, time + "ZipCode"],
        width: "1/4",
        default: null
      },
      {
        formComponent: "select-field",
        name: "state",
        label: "State",
        width: "1/4",
        path: [type, time + "State"],
        autoSelect: true,
        options: createOptionsForSelect({
          possibleValues: () => Object.keys(States),
          getOptionLabel: (x) => getStateLabelByState(x),
          getSelectedOption: (x, y) => x === y
        }),
        default: null
      },
      {
        formComponent: "select-field",
        name: "county",
        label: "County",
        width: "1/4",
        autoSelect: true,
        path: [type, time + "County"],
        options: createOptionsForSelect({
          possibleValues: (stateAccess: StateAccess) =>
            States[stateAccess.get([type, time + "State"]) as string]?.counties,
          getOptionLabel: (x) => x ?? " ",
          getSelectedOption: (x, y) => x === y
        }),
        default: null
      },
      {
        formComponent: "city-field",
        name: "city",
        label: "City",
        minLength: 3,
        path: [type, time + "City"],
        width: "1/4",
        default: null
      },
      {
        formComponent: "segment",
        width: "1/2",
        entities: [
          {
            formComponent: "months-field",
            name: "yearsAtCurrentAddress",
            label: "Yrs at addr.",
            path: [type, time === "current" ? "yearsAtCurrentAddress" : "yearsAtPreviousAddress"],
            width: "1/2",
            default: null
          },
          {
            formComponent: "months-field",
            name: "monthsAtCurrentAddress",
            label: "Mos at addr.",
            path: [type, time === "current" ? "monthsAtCurrentAddress" : "monthsAtPreviousAddress"],
            width: "1/2",
            default: null
          }
        ]
      }
    ]
  };
};

const applicantSegments = (type: "applicant" | "coApplicant") => {
  return [
    {
      formComponent: "segment",
      name: "Personal Info",
      width: "full",
      entities: [
        {
          formComponent: "text-field",
          name: "firstName",
          label: "First Name",
          width: "1/3",
          path: [type, "firstName"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "middleName",
          label: "Middle Name",
          width: "1/3",
          path: [type, "middleName"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "lastName",
          label: "Last Name",
          width: "1/3",
          path: [type, "lastName"],
          default: null
        },

        {
          formComponent: "phone-field",
          name: "mobilePhone",
          label: "Mobile Name",
          width: "1/3",
          path: [type, "mobilePhone"],
          default: null
        },
        {
          formComponent: "phone-field",
          name: "homePhone",
          label: "Home Name",
          width: "1/3",
          path: [type, "homePhone"],
          default: null
        },
        {
          formComponent: "email-field",
          name: "email",
          label: "Email",
          width: "1/3",
          path: [type, "email"],
          default: null
        },
        {
          formComponent: "ssn-field",
          name: "socialSecurityNumber",
          label: "SSN",
          width: 2,
          path: [type, "socialSecurityNumber"],
          default: null
        },
        {
          formComponent: "date-field",
          name: "birthDate",
          label: "Date of birth",
          width: 3,
          path: [type, "birthDate"],
          default: null
        },

        {
          formComponent: "radio-field",
          name: "usCitizen",
          label: "Is US citizen?",
          width: 3,
          path: [type, "usCitizen"],
          possibleValues: [
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" }
          ],
          default: "Yes"
        },
        {
          formComponent: "radio-field",
          name: "maritalStatus",
          label: "Marital status",
          width: 4,
          path: [type, "maritalStatus"],
          possibleValues: [
            { label: "Mar.", value: "Married" },
            { label: "Not m.", value: "Not married" }
          ],
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      name: "Driver License",
      width: "full",
      entities: [
        {
          formComponent: "text-field",
          name: "licenseNumber",
          label: "License number",
          width: "1/4",
          path: [type, "driverLicenseNumber"],
          default: null
        },
        {
          formComponent: "select-field",
          name: "state",
          label: "State",
          width: "1/4",
          path: [type, "drivingLicenseState"],
          options: createOptionsForSelect({
            possibleValues: () => Object.keys(States),
            getOptionLabel: (x) => getStateLabelByState(x),
            getSelectedOption: (x, y) => x === y
          }),
          default: null
        },
        {
          formComponent: "date-field",
          name: "licenseIssueDate",
          label: "License Issue date",
          width: "1/4",
          path: [type, "driverLicenseIssued"],
          disableRegion: "future",
          default: null
        },
        {
          formComponent: "date-field",
          name: "licenseExpiryDate",
          label: "License Expiry date",
          width: "1/4",
          disableRegion: "past",
          path: [type, "driverLicenseExpires"],
          default: null
        }
      ]
    },
    addressStruct(type, "current") as FormComponent<any>,
    {
      formComponent: "segment",
      name: "Property Info",
      width: "full",
      entities: [
        {
          formComponent: "radio-field",
          name: "propertyOwnership",
          label: "Property ownership",
          width: "2/4",
          path: [type, "propertyOwnership"],
          possibleValues: [
            { label: "Owner", value: "Owner" },
            { label: "Rent", value: "Rent" },
            { label: "Family", value: "Family" },
            { label: "Other", value: "Other" }
          ],
          default: "Owner"
        },
        {
          formComponent: "currency-field",
          name: "mortgagePerMonth",
          label: "Mortgage per month",
          width: "1/4",
          show: (stateAccess: StateAccess) =>
            stateAccess.get(["applicant", "propertyOwnership"]) !== "Rent",
          path: [type, "mortgagePerMonth"],
          default: null
        },
        {
          formComponent: "currency-field",
          name: "rentPerMonth",
          label: "Rent per month",
          width: "1/4",
          show: (stateAccess: StateAccess) =>
            stateAccess.get(["applicant", "propertyOwnership"]) === "Rent",
          path: [type, "rentPerMonth"],
          default: null
        }
      ]
    },
    addressStruct(type, "previous") as FormComponent<any>,

    {
      formComponent: "segment",
      width: "full",
      name: "Employment",
      entities: [
        {
          formComponent: "radio-field",
          name: "employmentStatus",
          label: "Employment status",
          width: "full",
          path: [type, "employmentStatus"],
          possibleValues: [
            { label: "Employee", value: "Employee" },
            { label: "Business owner", value: "Business owner" },
            { label: "Retired", value: "Retired" }
          ],
          default: null
        },
        {
          formComponent: "text-field",
          name: "sourceOfRetirementIncome",
          label: "Source of Retirement Income",
          width: "1/2",
          path: [type, "sourceOfRetirementIncome"],
          show: (stateAccess: StateAccess) =>
            stateAccess.get([type, "employmentStatus"]) === "Retired",
          default: null
        },
        {
          formComponent: "currency-field",
          name: "Retirement Income",
          label: "Retirement Income",
          width: "1/2",
          path: [type, "retirementIncome"],
          show: (stateAccess: StateAccess) =>
            stateAccess.get([type, "employmentStatus"]) === "Retired",
          default: null
        },

        {
          formComponent: "text-field",
          name: "employer",
          label: "Employer",
          width: "1/3",
          show: (stateAccess: StateAccess) =>
            stateAccess.get([type, "employmentStatus"]) === "User",
          path: [type, "employer"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "typeOfBusiness",
          label: "Type of business",
          width: "1/3",
          show: (stateAccess: StateAccess) =>
            stateAccess.get([type, "employmentStatus"]) === "Business owner",
          path: [type, "typeOfBusiness"],
          default: null
        },
        {
          formComponent: "phone-field",
          name: "businessPhone",
          label: "Business phone",
          width: "full",
          path: [type, "businessPhone"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "employer",
          label: "Employer",
          width: "1/3",
          path: [type, "employer"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "jobOccupation",
          label: "Job occupation",
          width: "1/3",
          path: [type, "jobOccupation"],
          default: null
        },
        {
          formComponent: "months-field",
          name: "yearsAtCurrentJob",
          label: "Years",
          path: [type, "yearsAtCurrentJob"],
          width: "1/6",
          min: 0,
          default: null
        },
        {
          formComponent: "months-field",
          name: "monthsAtCurrentJob",
          label: "Months",
          path: [type, "monthsAtCurrentJob"],
          width: "1/6",
          min: 0,
          default: null
        },

        {
          formComponent: "currency-field",
          name: "annualIncome",
          label: "Annual income",
          path: [type, "annualIncome"],
          width: "1/3",
          default: null,
          min: 0
        },

        {
          formComponent: "currency-field",
          name: "monthlyIncome",
          label: "Monthly income",
          path: [type, "monthlyIncome"],
          width: "1/3",
          default: null,
          min: 0
        },
        {
          formComponent: "text-field",
          name: "previousEmployer",
          label: "Previous Employer",
          width: "1/3",
          path: [type, "previousEmployer"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "previousOccupation",
          label: "Previous job occupation",
          width: "1/3",
          path: [type, "previousOccupation"],
          default: null
        },
        {
          formComponent: "months-field",
          name: "yearsAtPreviousJob",
          label: "Years",
          path: [type, "yearsAtPreviousJob"],
          width: "1/6",
          default: null,
          min: 0
        },
        {
          formComponent: "months-field",
          name: "monthsAtPreviousJob",
          label: "Months",
          path: [type, "monthsAtPreviousJob"],
          width: "1/6",
          default: null,
          min: 0
        },
        {
          formComponent: "list-model",
          name: "Additional Income",
          entity: {
            formComponent: "segment",
            entities: [
              {
                formComponent: "currency-field",
                name: "value",
                label: "Value",
                path: ["value"],
                width: "1/2",
                default: null
              },
              {
                formComponent: "text-field",
                name: "source",
                label: "Source",
                path: ["source"],
                required: true,
                width: "1/2",
                default: null
              }
            ] as FormComponent<AdditionalIncome>[]
          },
          width: "full",
          required: false,
          path: [type, "additionalIncomes"],
          renderOrder: "desc"
        }
      ]
    }
  ];
};

export const parsedDealStruct: FormComponent<ParsedDealData> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      name: "Applicant",
      style: {
        boxShadow:
          "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
        padding: 10
      },
      show: (stateAccess) => stateAccess.get(["applicant"]),
      width: (stateAccess) => "full",
      entities: applicantSegments("applicant") as FormComponent<ParsedDealData, any>[]
    },
    {
      formComponent: "segment",
      name: "Co Applicant",
      style: {
        boxShadow:
          "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
        padding: 10
      },
      width: "full",
      show: (stateAccess) => stateAccess.get(["coApplicant"]),
      entities: applicantSegments("coApplicant") as FormComponent<ParsedDealData, any>[]
    },
    {
      formComponent: "segment",
      name: "Collateral",
      style: {
        boxShadow:
          "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
        padding: 10
      },
      width: "1/2",
      show: (stateAccess) => stateAccess.get(["vehicle"]),
      entities: [
        {
          formComponent: "text-field",
          name: "Make",
          label: "Make",
          width: "1/4",
          path: ["vehicle", "make"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "model",
          label: "Model",
          width: "1/4",
          path: ["vehicle", "model"],
          default: null
        },
        {
          formComponent: "year-field",
          name: "year",
          label: "Year",
          width: "1/4",
          path: ["vehicle", "year"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "vin",
          label: "VIN",
          width: "1/4",
          path: ["vehicle", "VIN"],
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      name: "Finance",
      style: {
        boxShadow:
          "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
        padding: 10
      },
      width: "1/2",
      show: (stateAccess) => stateAccess.get(["price"]),
      entities: [
        {
          formComponent: "currency-field",
          name: "Price",
          label: "Price",
          width: "1/4",
          path: ["price", "price"],
          default: null
        }
      ]
    }
  ]
};
