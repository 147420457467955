export function getAge(dateString: string): number {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export enum CustomRegexes {
  SSN_WITH_DASHES = "(?!\b(\\d)\\1+-(\\d)\\1+-(\\d)\\1+\b)(?!123-45-6789|219-09-9999|078-05-1120)(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}",
  SSN = "(?!\\b(\\d)\\1+\\b)(?!123456789|219099999|078051120)(?!666|000\\d{2})\\d{3}(?!00)\\d{2}(?!0{4})\\d{4}",
  LETTERS = "^[^0-9]*$",
  NUMBERS = "^[0-9]+$",
  EMAIL = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
  PHONE_WITH_DASHES = "^(\\([2-9][0-9][0-9]\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$",
  PHONE = "^1?[2-9]{1}[0-9]{9}$",
  DECIMAL_STRING = "^(-?)\\d+(\\.\\d{1,3})?$",
  ZIP_CODE = "(^\\d{5}$)|(^\\d{5}-\\d{4}$)"
}
export const getEnv = () => {
  switch (process.env.REACT_APP_FIREBASE_PROJECT_ID) {
    case "wfd-frontend-dev":
      return "dev";
    case "wfd-frontend-stable":
      return "stable";
    case "wfd-frontend":
      return "production";

    default:
      return process.env.NODE_ENV;
  }
};
export const notifyWebhooksError = async (error: any) => {
  try {
    const env = getEnv();
    if (env === "production") return;
    const url = process.env.DISCORD_WEBHOOK_URL ?? process.env.REACT_APP_DISCORD_WEBHOOK_URL;
    if (!url) throw "Discord URL is not set!";
    console.log(error);
    // const Hook = new webhook.Webhook(url);
    // Hook.warn(`Validation - ${env}`, JSON.stringify(error).slice(0, 1000));
  } catch (error) {
    console.error("NOTIFY WEBHOOKS FUNCTION ERROR: ", error);
  }
};
