import React, { useState } from "react";
import { StateAccess, RenderSet, getByPath, setByPath } from "utils/models/formGenerator";
import { createOptionsForSelect, Path } from "utils/models/fields";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { getLenderList, removeLenderList } from "components/Lenders/listLenderSlice";
import { v4 as uuidv4 } from "uuid";
import { Deal } from "../Deals/types";
import { Lender, OperatesInStateStatus, SupportedDealershipStatus } from "components/Lenders/types";
import { getCreditBureausScore } from "./model";
import { useLenderCreditStatisticsInDeal } from "hooks/useLenderDecisionStatistics/useLenderDecisionStatistics";
import { getFirstAndLastDateOfAYear } from "utils/functions";
import { Typography } from "@material-ui/core";
import { Dealership } from "components/Dealerships/types";

interface Props<T> {
  stateAccess: StateAccess;
  path: Path<T>;
  lenderIdPath: Path<T>;
  renderSet: RenderSet;
  name: string;
  label: string;
  showCheckbox?: boolean;
  required?: (stateAccess: StateAccess) => any;
}

export default function LenderPreview<T extends Deal>({
  stateAccess,
  path,
  lenderIdPath,
  renderSet,
  name,
  label,
  required
}: Props<T>) {
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const listId = `list-autocomplete-lender-${requestId}`;
  const lenderList = useSelector((state: RootState) => state.listLenderSlice[listId]);
  const deal: Deal = stateAccess.get<Deal>([]);
  const creditScore = getCreditBureausScore(deal, "applicant")?.score;
  const collateralType = deal?.data?.info?.type;
  const dealershipId = deal?.data?.dealership?._id || deal?.data?.dealershipId || undefined;
  const state = deal?.data?.applicant?.data?.info?.currentState;
  const county = deal?.data?.applicant?.data?.info?.currentCounty;
  const lender: Lender = stateAccess.get<T>(path);

  const lenderStateAccess: StateAccess = {
    get: (path) => getByPath<Lender>(lender, path as Path<Lender>),
    set: (_, value): any => {
      stateAccess.set<T>([], setByPath(setByPath(deal, lenderIdPath, value?._id), path, value));
    }
  };
  const { from, to } = getFirstAndLastDateOfAYear(new Date().getFullYear());

  const { data } = useLenderCreditStatisticsInDeal({
    creditScore: creditScore ? Number(creditScore) : 0,
    state,
    county,
    dealershipId,
    collateralType,
    from,
    to
  });
  const dispatchGetLenderList = (showAllLenders = false) => {
    dispatch(
      getLenderList(listId, {
        query: { "data.info.operatingStates.status": { $ne: OperatesInStateStatus.INACTIVE } },

        options: { pagination: false, sort: { "data.info.name": "asc" } }
      })
    );
  };

  const handleLoadLenders = () => {
    if (!lenderList) dispatchGetLenderList();
    return () => {
      dispatch(removeLenderList(listId));
      return;
    };
  };

  return (
    <>
      {renderSet.selectInputRenderer(
        {
          formComponent: "select-field",
          name,
          label,
          path: [],
          onOpen: handleLoadLenders,
          options: createOptionsForSelect({
            renderOption: (option, state, getOptionLabel) => {
              const foundInCreditStatistics = data?.lenders?.find(
                (lender) => lender._id === option._id
              );
              return (
                <Typography
                  style={foundInCreditStatistics ? { color: "rgb(80, 165, 56)" } : undefined}
                >
                  {getOptionLabel ? getOptionLabel(option) : ""}
                </Typography>
              );
            },
            possibleValues: () => lenderList?.entities ?? [],
            getOptionLabel: (lender) => {
              return lender?.data?.info?.name ?? "";
            },
            getSelectedOption: (x, y) => {
              return x._id === y._id;
            }
          }),
          valueToString: (el) => el?.data?.info?.name,
          required: required && required(stateAccess)
        },
        lenderStateAccess,
        [],
        lenderStateAccess,
        renderSet
      )}
    </>
  );
}
