import axios from "axios";
import { useMutation } from "@tanstack/react-query";

import { MutationError } from "Chat/types";
import { Deal } from "components/Deals/types";

import { auth } from "../../firebase/firebase";
import { useSnackbar } from "notistack";

type PredictionRequest = {
  _id: string;
};
export const predict = (): ((request: PredictionRequest) => Promise<Deal>) => {
  return async (request) => {
    try {
      const token = await auth.currentUser?.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_HTTP_URL}/api/v1/deal_manual_prediction/`,
        request,
        {
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      );
      return response.data.result;
    } catch (err: any) {
      throw new Error(
        err?.response?.data?.message ??
          err?.response?.data?.result ??
          err?.message ??
          "Unknown error!"
      );
    }
  };
};

const useDealManualPrediction = () => {
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation<Deal, MutationError, PredictionRequest>(predict(), {
    onSuccess: () => enqueueSnackbar("Prediction performed successfully.", { variant: "success" })
  });
  return {
    dealManualPrediction: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};
export default useDealManualPrediction;
