import React from "react";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import useDealManualPrediction from "hooks/useDealManualPrediction/useDealManualPrediction";

export default function ManualPredictionButton({ dealId }: { dealId: string }) {
  const { dealManualPrediction, loading } = useDealManualPrediction();
  return (
    <div>
      {!loading ? (
        <Tooltip placement="top" title="Click here to perform prediction.">
          <IconButton id="perform_prediction" onClick={() => dealManualPrediction({ _id: dealId })}>
            <InsertChartIcon color="primary" fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}
