import React from "react";
import { Tooltip } from "@material-ui/core";
import ReportIcon from "@material-ui/icons/Report";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { Prediction } from "types/prediction";

const getPredictionIcon = (prediction?: Prediction) => {
  if (prediction?.predictionFailed) {
    return (
      <Tooltip title="Prediction Unavailable with current data" placement="top">
        <ReportIcon style={{ color: "#eed205", fontSize: "18px" }} />
      </Tooltip>
    );
  }
  if (prediction?.isCorrect === true) {
    return (
      <Tooltip title="Correct Prediction" placement="top">
        <CheckCircleIcon style={{ color: "#4caf50", fontSize: "18px" }} />
      </Tooltip>
    );
  }
  if (prediction?.isCorrect === false) {
    return (
      <Tooltip title="Incorrect Prediction" placement="top">
        <HighlightOffIcon style={{ color: "#f50057", fontSize: "18px" }} />
      </Tooltip>
    );
  }
  return null;
};

const PredictionIcon = ({ prediction }: { prediction?: Prediction }) =>
  getPredictionIcon(prediction);

export default PredictionIcon;
