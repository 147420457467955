import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import React from "react";
import { RenderSet, setByPath, StateAccess } from "utils/models/formGenerator";
import { Lender, OperatesInStateStatus, SupportedDealershipStatus } from "./types";
import OperatingStatesSetter, { getSupportedCollateralTypes } from "./OperatingStatesSetter";
import States from "us-states";
import { createOptionsForSelect, FormComponent } from "utils/models/fields";
import { getStateLabelByState } from "utils/functions";
import { collateralTypes } from "components/Deals/types";
import { User } from "components/Users/types";
import { RootState } from "app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import formEditContext from "components/Content/FormEditContext";
import StatusHistoryButton from "./StatusHistoryButton";
import {
  getDealershipList,
  removeDealershipList
} from "components/Dealerships/listDealershipSlice";
import { uuid } from "uuidv4";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Dealership } from "components/Dealerships/types";

type Props = {
  stateAccess: StateAccess;
  renderSet: RenderSet;
};
export default ({ stateAccess, renderSet }: Props) => {
  const dispatch = useDispatch();
  const listId = "supportedDealerships";
  const dealershipList = useSelector((state: RootState) => state.listDealershipSlice[listId]);

  const currentUser = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);
  const lenderCurrentUser: User | undefined = stateAccess.get<User>(["data", "currentUser"]);
  const oldOperatingStates: User | undefined = stateAccess.get<User>([
    "data",
    "oldOperatingStates"
  ]);
  const { initialState } = React.useContext(formEditContext);
  const initialLender = initialState as Lender;
  const changed =
    JSON.stringify(stateAccess.get(["data", "info", "operatingStates"])) !==
    JSON.stringify(initialLender?.data?.info?.operatingStates);
  React.useEffect(() => {
    if (!dealershipList)
      dispatch(
        getDealershipList(listId, {
          options: {
            pagination: false,
            sort: { "data.info.displayName": "asc" },
            projection: {
              _id: 1,
              createdAt: 1,
              approved: 1,
              deleted: 1,
              "data.info.displayName": 1,
              "data.info.name": 1
            }
          }
        })
      );
    return () => {
      dispatch(removeDealershipList(listId));
      return;
    };
  }, [listId]);
  React.useEffect(() => {
    if (currentUser && !lenderCurrentUser) {
      if (!oldOperatingStates) {
        stateAccess.set(
          [],
          setByPath(
            setByPath(
              stateAccess.get([]),
              ["data", "oldOperatingStates"],
              stateAccess.get(["data", "info", "operatingStates"])
            ),
            ["data", "currentUser"],
            currentUser
          )
        );
      } else {
        stateAccess.set(["data", "currentUser"], currentUser);
      }
    }
  }, [currentUser, lenderCurrentUser]);
  return (
    <Grid container>
      <Paper elevation={3} style={{ width: "100%", padding: "10px" }}>
        <Grid item xs={12}>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <Typography
              style={{
                color: "rgb(37, 78, 110)",
                fontSize: "19px",
                fontWeight: "bold",
                margin: "5px 0px"
              }}
            >
              Operates in area
            </Typography>
            <StatusHistoryButton stateAccess={stateAccess} />
          </div>
        </Grid>
        <Grid item xs={6}>
          {renderSet.radioInputRenderer<Lender>(
            {
              formComponent: "radio-field",
              name: "Operates IN",
              onChange: (stateAccess, stateStatus) => {
                if (stateStatus === OperatesInStateStatus.ACTIVE) {
                  const supportedStates = getSupportedCollateralTypes(
                    stateAccess.get(["data", "info", "collateralTypes"]),
                    Object.keys(States)
                  );
                  stateAccess.set(["data", "info", "operatingStates", "states"], supportedStates);
                } else if (stateStatus === OperatesInStateStatus.INACTIVE) {
                  stateAccess.set(["data", "info", "operatingStates", "states"], []);
                }
              },
              label: "Operates In",
              path: ["data", "info", "operatingStates", "status"],
              required: true,
              possibleValues: [
                { label: "All states", value: OperatesInStateStatus.ACTIVE },
                { label: "Some states", value: OperatesInStateStatus.STATE },
                { label: "Inactive", value: OperatesInStateStatus.INACTIVE }
              ],
              default: null
            },
            stateAccess,
            renderSet
          )}
        </Grid>
        {stateAccess.get(["_id"]) ? (
          <Grid item xs={2}>
            <OperatingStatesSetter stateAccess={stateAccess} />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          {renderSet.tabListModelRenderer(
            {
              formComponent: "tab-list-model",
              name: "Add operating state",
              getTabName: (entity) => entity.state ?? "Select state",
              show: (stateAccess) =>
                [OperatesInStateStatus.STATE, OperatesInStateStatus.ACTIVE].includes(
                  stateAccess.get(["data", "info", "operatingStates", "status"])
                ),
              path: ["data", "info", "operatingStates", "states"],
              entity: {
                formComponent: "segment",
                width: "full",
                entities: [
                  {
                    formComponent: "segment",
                    name: "State restriction settings",
                    width: "full",
                    entities: [
                      {
                        formComponent: "select-field",
                        name: "State",
                        label: "Operating state",
                        width: "1/4",
                        path: ["state"],
                        options: createOptionsForSelect({
                          possibleValues: () => Object.keys(States),
                          getOptionLabel: (x) => getStateLabelByState(x),
                          getSelectedOption: (x, y) => x === y
                        })
                      },
                      {
                        formComponent: "radio-field",
                        name: "Status",
                        label: "Operating status in state",
                        width: "1/4",
                        required: true,
                        path: ["status"],
                        default: null,
                        possibleValues: [
                          { label: "Active", value: OperatesInStateStatus.ACTIVE },
                          { label: "Inactive", value: OperatesInStateStatus.INACTIVE },
                          { label: "Active In Some Counties", value: OperatesInStateStatus.COUNTY }
                        ]
                      },
                      {
                        formComponent: "radio-field",
                        name: "Supported dealerships state",
                        label: "Dealership restrictions in this state",
                        width: "1/6",
                        required: true,
                        path: ["supportedDealerships", "status"],
                        default: null,
                        possibleValues: [
                          {
                            label: "Works with all dealerships",
                            value: SupportedDealershipStatus.ALL
                          },
                          {
                            label: "Works with specific dealerships",
                            value: SupportedDealershipStatus.SPECIFIC
                          }
                        ]
                      },
                      dealershipMultiSelect(
                        "supported-dealerships-select-states",
                        dealershipList?.entities ?? []
                      )
                    ]
                  },

                  {
                    formComponent: "segment",
                    show: (stateAccess: StateAccess) =>
                      stateAccess.get(["status"]) === OperatesInStateStatus.COUNTY,
                    width: "full",
                    entities: [
                      {
                        formComponent: "list-model",
                        name: "County restriction settings",
                        entity: {
                          formComponent: "segment",
                          entities: [
                            {
                              formComponent: "multiselect-field",
                              name: "Counties",
                              label: "Operates in counties",
                              width: "1/3",
                              path: ["counties"],
                              options: createOptionsForSelect({
                                possibleValues: (stateAccess, path, mainStateAccess) => {
                                  const parentPath = path.slice(0, -2);
                                  return (
                                    States[mainStateAccess.get(parentPath)?.state]?.counties ?? []
                                  );
                                },
                                getOptionLabel: (x) => x,
                                getSelectedOption: (x, y) => x === y
                              })
                            },
                            {
                              formComponent: "radio-field",
                              name: `${uuid()}-supported-dealerships-county`,
                              label: "Dealership restrictions in this county",
                              width: "1/6",
                              required: true,
                              path: ["supportedDealerships", "status"],
                              default: null,
                              possibleValues: [
                                {
                                  label: "Works with all dealerships",
                                  value: SupportedDealershipStatus.ALL
                                },
                                {
                                  label: "Works with specific dealerships",
                                  value: SupportedDealershipStatus.SPECIFIC
                                }
                              ]
                            },
                            dealershipMultiSelect(
                              "supported-dealerships-select-counties",
                              dealershipList?.entities ?? []
                            )
                          ]
                        },
                        width: "full",
                        required: false,
                        path: ["operatingCounties"],
                        renderOrder: "desc"
                      }
                    ]
                  },
                  {
                    formComponent: "segment",
                    width: "full",
                    show: (stateAccess: StateAccess) =>
                      stateAccess.get(["status"]) !== OperatesInStateStatus.INACTIVE,
                    name: "Supported collateral types in this state",
                    entities: collateralTypes.map((type) => ({
                      formComponent: "checkbox-field",
                      name: "automotiive",
                      label: type,
                      width: "1/4",
                      valueType: "boolean",
                      path: ["collateralTypes", type],
                      isDisabled: (): boolean => false,
                      isChecked: (stateAccess: StateAccess): boolean =>
                        stateAccess.get(["collateralTypes", type]),
                      toggle: (stateAccess: StateAccess) =>
                        stateAccess.set(
                          ["collateralTypes", type],
                          !stateAccess.get(["collateralTypes", type])
                        ),
                      required: true,
                      default: false
                    }))
                  }
                ]
              }
            },
            stateAccess,
            [],
            stateAccess,
            renderSet
          )}
        </Grid>
        {changed ? (
          <Grid item xs={6}>
            {renderSet.textInputRenderer(
              {
                type: "text",
                name: "status-comment",
                label: "Comment",
                required: true,
                path: ["data", "info", "operatingStates", "comment"],
                multiline: true,
                rows: 3
              },
              stateAccess,
              renderSet
            )}
          </Grid>
        ) : null}
      </Paper>
    </Grid>
  );
};

const dealershipMultiSelect = (id: string, dealerships: Dealership[]) => {
  return {
    formComponent: "one-to-many-field",
    name: "Dealerships",
    width: "auto",
    show: (stateAccess) =>
      stateAccess.get(["supportedDealerships", "status"]) === SupportedDealershipStatus.SPECIFIC,
    path: [],
    component: (_stateAccess, mainStateAccess, _renderSet, parentPath) => {
      const path = [...(parentPath ?? []), "supportedDealerships", "ids"];
      return (
        <Autocomplete
          style={{ zIndex: 6 }}
          value={(dealerships ?? [])?.filter((dealership) =>
            mainStateAccess.get(path)?.includes(dealership._id)
          )}
          getOptionLabel={(dealership) => {
            return dealership.data.info.displayName ?? "";
          }}
          multiple
          getOptionSelected={(x, y) => x?._id === y?._id && x === y}
          options={dealerships ?? []}
          onChange={(event, selectedDealerships) => {
            const ids = selectedDealerships?.map((dealership) => dealership._id);
            mainStateAccess.set(path, ids);
          }}
          loading={!Array.isArray(dealerships)}
          openOnFocus
          id={id}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              required
              InputProps={{
                ...params.InputProps,
                required: mainStateAccess.get(path)?.length === 0
              }}
              name={id}
              label={"Dealerships"}
              variant="filled"
            />
          )}
        />
      );
    }
  } as FormComponent<Lender>;
};
