import { Button } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { printTemplatesWithDataById } from "components/Deals/PrintRecapSheet";
import { showLoader, hideLoader } from "components/Loader/loaderSlice";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch } from "react-redux";
import { Path } from "utils/models/fields";
import {
  fillDefaultsByPath,
  generateDefault,
  getByPath,
  setByPath,
  StateAccess
} from "utils/models/formGenerator";
import { applicantStruct } from "../Applicants/model";
import { Applicant, PropertyOwnership } from "../Applicants/types";
import { addDealStruct } from "../Deals/model";
import { Deal } from "../Deals/types";
import { ExternalCreditApplication, ResidentialStatus } from "./types";

const fileId = "602a344d8e2ae81a0fe287ee";

interface Props {
  stateAccess: StateAccess;
  type: "credit smarts" | "external application" | "deal";
  style?: React.CSSProperties;
}
export const resolvePropertyOwnership = (status: ResidentialStatus): PropertyOwnership => {
  switch (status) {
    case "own":
      return "Owner";
    case "rent":
      return "Rent";
    case "withRelatives":
      return "Family";
    default:
      return "Other";
  }
};
const parseApplicant = (
  real:
    | ExternalCreditApplication["data"]["info"]["applicant"]
    | ExternalCreditApplication["data"]["info"]["coApplicant"]
): Applicant => {
  const defaultApplicant = generateDefault(
    applicantStruct as any,
    {},
    fillDefaultsByPath as any
  ) as Applicant;
  return {
    _id: "",
    createdAt: new Date().toString(),
    updatedAt: new Date().toString(),
    data: {
      info: {
        ...defaultApplicant?.data?.info,
        firstName: real?.firstName,
        middleName: real?.middleName,
        lastName: real?.lastName,
        birthDate: new Date(real?.dateOfBirth),
        socialSecurityNumber: real?.ssn,
        mobilePhone: real?.primaryPhone,
        homePhone: real?.secondaryPhone,
        businessPhone: real?.currentEmployer?.workPhone,
        email: real?.email,
        currentZipCode: real?.currentAddress?.zip,
        currentAddress: [
          real?.currentAddress?.addressNumber,
          real?.currentAddress?.address,
          real?.currentAddress?.apt
        ].join(" "),
        currentCity: real?.currentAddress?.city,
        currentState: real?.currentAddress?.state,
        // check if this should not be "years" as months + "months"
        monthsAtCurrentAddress: parseInt(real?.currentAddress?.time?.months),
        yearsAtCurrentAddress: parseInt(real?.currentAddress?.time?.years),
        previousZipCode: real?.previousAddress?.zip,
        previousAddress: [
          real?.previousAddress?.addressNumber,
          real?.previousAddress?.address,
          real?.previousAddress?.apt
        ].join(" "),
        previousCity: real?.previousAddress?.city,
        previousState: real?.previousAddress?.state,
        // check if this should not be "years" as months + "months"
        monthsAtPreviousAddress: parseInt(real?.previousAddress?.time?.months),
        yearsAtPreviousAddress: parseInt(real?.previousAddress?.time?.years),
        employer: real?.currentEmployer?.name,
        jobOccupation: real?.currentEmployer?.occupationOrJobTitle,
        monthsAtCurrentJob: parseInt(real?.currentEmployer?.time?.months),
        yearsAtCurrentJob: parseInt(real?.currentEmployer?.time?.years),
        previousEmployer: real?.prevEmployer?.name,
        previousOccupation: real?.prevEmployer?.occupationOrJobTitle,
        monthsAtPreviousJob: parseInt(real?.prevEmployer?.time?.months),
        yearsAtPreviousJob: parseInt(real?.prevEmployer?.time?.years),
        monthlyIncome: real?.currentEmployer?.grossMonthlySalary,
        // Driver license
        driverLicenseNumber: real?.idNumber,
        drivingLicenseState: real?.idIssuedBy,
        driverLicenseIssued: new Date(real?.idIssuedDate),
        driverLicenseExpires: new Date(real?.idExpDate),
        ...(real?.residentialStatus === "rent"
          ? { rentPerMonth: real?.rentOrMortgageAmount }
          : { mortgagePerMonth: real?.rentOrMortgageAmount }),
        propertyOwnership: resolvePropertyOwnership(real?.residentialStatus),
        additionalIncome: real?.grossMonthlyOtherIncome,
        additionalIncomes: [
          { value: real?.grossMonthlyOtherIncome || null, source: real?.otherIncomeSource || null }
        ],
        sourceOfAdditionalIncome: real?.otherIncomeSource
      }
    }
  };
};

const parseDeal = (real: ExternalCreditApplication): Deal => {
  const realApplicant = real.data.info.applicant;
  const realCoApplicant = real.data.info.coApplicant;
  const defaultDeal = generateDefault(addDealStruct as any, {}, fillDefaultsByPath as any) as Deal;
  return {
    ...defaultDeal,
    data: {
      ...defaultDeal.data,
      dealership: real.data.dealership,
      info: {
        ...defaultDeal.data.info,
        vehicle: {
          ...defaultDeal.data.info.vehicle,
          make: real.data.info.collateral?.make,
          year: parseInt(real.data.info.collateral?.year),
          model: real.data.info.collateral?.model,
          odometer: parseInt(real.data.info.collateral?.miles)
        },
        price: {
          ...defaultDeal.data.info.price,
          totalCash: real?.data?.info?.price?.totalCash,
          trade: real.data.info.tradeIn
            ? [
                {
                  make: real.data.info.tradeIn.make,
                  year: parseInt(real.data.info.tradeIn.year),
                  model: real.data.info.tradeIn.model
                }
              ]
            : undefined
        }
      },
      applicant: parseApplicant(realApplicant),
      coApplicant: realCoApplicant ? parseApplicant(realCoApplicant) : null
    }
  };
};

export default function Print({
  type,
  stateAccess,
  style = { position: "absolute", right: "30px" }
}: Props) {
  const dealData = stateAccess.get([] as any);
  const deal = type !== "deal" ? parseDeal(dealData) : dealData;
  const dealState = {
    get: (path: Path<Deal>) => getByPath(deal, path),
    set: (path: Path<Deal>, value): any => setByPath<Deal>(deal, path, value)
  } as StateAccess;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const handleDocumentPrint = async () => {
    dispatch(showLoader());
    await printTemplatesWithDataById(
      fileId,
      dealState,
      enqueueSnackbar,
      "external_credit_application"
    );
    dispatch(hideLoader());
  };

  return (
    <Button
      style={style}
      variant="contained"
      color="primary"
      onClick={handleDocumentPrint}
      startIcon={<PrintIcon />}
    >
      {type !== "deal" ? "Print" : "Print Application"}
    </Button>
  );
}
