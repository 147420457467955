import React from "react";

import { toMonthName } from "components/Reports/DealershipBillsReport/model";

import { Chargeback } from "../types";
import { createOptionsForSelect, Model } from "../../../utils/models/fields";

export const monthlyChargebacks: Model<Chargeback> = {
  formComponent: "model",
  schema: "all_chargebacks",
  name: "chargeback",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      name: "Monthly Chargebacks",
      entities: [
        {
          formComponent: "select-field",
          path: ["year"],
          width: "1/3",
          name: "year",
          label: "Year",
          required: true,
          options: createOptionsForSelect({
            possibleValues: () => {
              const date = new Date().getFullYear();
              return [date - 1, date, date + 1];
            },
            getOptionLabel: (x) => x?.toString(),
            getSelectedOption: (x, y) => x === y
          }),
          valueToString: (x) => x,
          default: new Date().getFullYear()
        },
        {
          formComponent: "select-field",
          path: ["month"],
          width: "1/3",
          name: "month",
          required: true,
          label: "Month",
          options: createOptionsForSelect({
            possibleValues: () => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            getOptionLabel: (x) => toMonthName(x),
            getSelectedOption: (x, y) => x === y
          }),
          default: new Date().getMonth() === 0 ? 12 : new Date().getMonth(),
          valueToString: (x) => x
        }
      ]
    }
  ]
};
