import {
  multyPermissionNode,
  permissionNode,
  PermissionStruct,
  relationPermissionNode
} from "components/Roles/types";
import { Applicant } from "./types";

export type ApplicantPermissionsStruct = PermissionStruct<Applicant>;

export const applicantPermissionStruct: ApplicantPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      warrantyOrPreApprovalOnly: permissionNode("Warranty or Pre-Approval only"),
      coordinates: {
        lat: permissionNode("Latitute"),
        lon: permissionNode("Longitude")
      },
      refNumber: permissionNode("RefNumber"),
      suffix: permissionNode("Suffix"),
      firstName: permissionNode("First Name"),
      middleName: permissionNode("Middle Name"),
      lastName: permissionNode("Last Name"),
      socialSecurityNumber: permissionNode("Social Security Number"),
      driverLicenseNumber: permissionNode("Driver License Number"),
      drivingLicenseState: permissionNode("Driving License State"),
      driverLicenseIssued: permissionNode("Driver License Issued"),
      driverLicenseExpires: permissionNode("Driver License Expires"),
      birthDate: permissionNode("Birth Date"),
      currentCountry: permissionNode("Current Country"),
      currentState: permissionNode("Current State"),
      currentStatePrefix: permissionNode("Current StatePrefix"),
      currentCounty: permissionNode("Current County"),
      currentAddress: permissionNode("Current Address"),
      currentAddressNumber: permissionNode("Current Address Number"),
      currentCity: permissionNode("Current City"),
      currentZipCode: permissionNode("Current Zip Code"),
      monthsAtCurrentAddress: permissionNode("Months At Current Address"),
      yearsAtCurrentAddress: permissionNode("Years At Current Address"),
      previousCountry: permissionNode("Previous Country"),
      previousState: permissionNode("Previous State"),
      previousCounty: permissionNode("Previous County"),
      previousAddress: permissionNode("Previous Address"),
      previousAddressNumber: permissionNode("Previous Address Number"),
      previousCity: permissionNode("Previous City"),
      previousZipCode: permissionNode("Previous Zip Code"),
      monthsAtPreviousAddress: permissionNode("Months At Previous Address"),
      yearsAtPreviousAddress: permissionNode("Years At Previous Address"),
      propertyOwnership: permissionNode("Property Ownership"),
      mortgageOrRent: permissionNode("Mortgage Or Rent"),
      mortgagePerMonth: permissionNode("Mortgage Per Month"),
      rentPerMonth: permissionNode("RentPerMonth"),
      previousPropertyOwnership: permissionNode("PreviousPropertyOwnership"),
      previousMortgagePerMonth: permissionNode("PreviousMortgagePerMonth"),
      previousRentPerMonth: permissionNode("PreviousRentPerMonth"),
      maritalStatus: permissionNode("MaritalStatus"),
      usCitizen: permissionNode("Us Citizen"),
      employmentStatus: permissionNode("Employment Status"),
      employer: permissionNode("Employer"),
      typeOfBusiness: permissionNode("Type Of Business"),
      jobOccupation: permissionNode("Job Occupation"),
      monthsAtCurrentJob: permissionNode("Months At Current Job"),
      yearsAtCurrentJob: permissionNode("Years At Current Job"),
      annualIncome: permissionNode("Annual Income"),
      monthlyIncome: permissionNode("Monthly Income"),
      additionalIncomes: multyPermissionNode("Additional Incomes"),
      additionalIncome: permissionNode("Additional Income"),
      sourceOfAdditionalIncome: permissionNode("Source Of Additional Income"),
      previousEmployer: permissionNode("Previous Employer"),
      previousOccupation: permissionNode("Previous Occupation"),
      monthsAtPreviousJob: permissionNode("Months At Previous Job"),
      yearsAtPreviousJob: permissionNode("Years At Previous Job"),
      email: permissionNode("Email"),
      homePhone: permissionNode("Home Phone"),
      mobilePhone: permissionNode("Mobile Phone"),
      businessPhone: permissionNode("Business Phone"),
      callAtWork: permissionNode("Call At Work"),
      retirementIncome: permissionNode("Retirement Income"),
      sourceOfRetirementIncome: permissionNode("Source of retirement income")
    }
  }
};
