import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { Theme, withStyles } from "@material-ui/core/styles";
import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";
import { getStyleByStatus, statusToChipTooltip } from "components/Deals/Deals";
import {
  AddDealFromDocumentRequest,
  addDealFromParsedDocument
} from "components/Deals/addDealFromParsedDocumentSlice";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import { TabContext } from "components/Layout/LayoutWrapper";
import { hideLoader, showLoader } from "components/Loader/loaderSlice";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModel } from "utils/entitySlice";
import formatDate from "utils/formatDate";
import { assertNever, capitalize } from "utils/functions";
import { lockEntityFunction } from "utils/models/ShowForm";
import { fillDefaultsByPath, generateDefault } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import AccessControl from "../Access/AccessControl";
import { calculate } from "../Deals/Calculations";
import { addDeal } from "../Deals/addDealSlice";
import { deleteDeal } from "../Deals/deleteDealSlice";
import { getDealList } from "../Deals/listDealSlice";
import { recoverDeal } from "../Deals/recoverDealSlice";
import { Deal, DealData, DealStatus } from "../Deals/types";
import ApplicantFilter from "../Filters/ApplicantFilter";
import DateFilter from "../Filters/DateFilter";
import DealershipFilter from "../Filters/DealershipFilter";
import LenderFilter from "../Filters/LenderFilter";
import PriceFilter from "../Filters/PriceFilter";
import TextFilter from "../Filters/TextFilter";
import UserFilter from "../Filters/UserFilter";
import Table, { CellValue, Column } from "../Table";
import { Typography } from "@material-ui/core";
import HtmlTooltip from "components/Deals/DealsLog/HTMlLTooltip";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PredictionIcon from "components/Deals/PredictionIcon";

export const projections = {
  "data.applicant.data.info.firstName": 1,
  "data.applicant.data.info.lastName": 1,
  "data.coApplicant.data.info.firstName": 1,
  "data.coApplicant.data.info.lastName": 1,
  "data.info.status": 1,
  "data.notes": 1,
  "data.info.refNumber": 1,
  "data.info.prediction.decision": 1,
  "data.info.prediction.isCorrect": 1,
  "data.dealership.data.info.displayName": 1,
  "data.dealership.data.representative.data.info.firstName": 1,
  "data.dealership.data.representative.data.info.lastName": 1,
  "data.info.vehicle.make": 1,
  "data.info.vehicle.model": 1,
  "data.info.vehicle.VIN": 1,
  "data.lender.data.info.name": 1,
  "data.user.data.info.firstName": 1,
  "data.user.data.info.lastName": 1,
  "data.info.extractedCreditScores": 1,
  "data.info.creditCheckDate": 1,
  "data.userId": 1,
  "data.user._id": 1,
  "data.info.payment.fundedAmount": 1,
  "data.info.dealDates": 1,
  "data.order": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};

const addEntityFunction = (dispatch: Dispatch<any>, requestId: string) => () => {
  const defaultState: Deal = generateDefault(
    getModel("deal") as any,
    {},
    fillDefaultsByPath as any
  ) as Deal;
  const newDeal: Deal = calculate(defaultState, defaultState);
  dispatch(
    addDeal({
      requestId,
      ...newDeal
    })
  );
};
const addEntityFromDocumentFunction = (dispatch: Dispatch<any>, requestId: string) => (
  state: AddDealFromDocumentRequest
) => {
  dispatch(
    addDealFromParsedDocument({
      requestId,
      ...state
    })
  );
};
export default function (): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const dealList = useSelector((state: RootState) => state.listDealSlice["leads"]);
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const addDealState = useSelector((state: RootState) => state.addDealSlice[requestId]);
  const addDealFromParsedDocumentState = useSelector(
    (state: RootState) => state.addDealFromParsedDocumentSlice[requestId]
  );
  const createOrFocusTab = useContext(TabContext);
  useEffect(() => {
    if (addDealFromParsedDocumentState?.status === "waiting") {
      dispatch(showLoader());
    }
    if (addDealFromParsedDocumentState?.status === "success") {
      enqueueSnackbar(`Successfully created a deal.`, { variant: "success" });
      dispatch(hideLoader());
    }
    if (addDealFromParsedDocumentState?.status === "error") {
      enqueueSnackbar(addDealFromParsedDocumentState?.message ?? "Something went wrong", {
        variant: "error"
      });
      dispatch(hideLoader());
    }
  }, [addDealFromParsedDocumentState?.status]);
  useEffect(() => {
    if (
      addDealState !== undefined &&
      addDealState.status === "success" &&
      addDealState.data !== null
    ) {
      lockEntityFunction(dispatch, addDealState.data.message._id, "deal", requestId);
      createOrFocusTab({
        label: "Show page",
        index: "showPage",
        isForSidebar: false,
        isForQuickAccess: false,
        isPersistent: false,
        props: {
          _id: addDealState.data.message._id,
          type: "deal"
        }
      });
    }
  }, [addDealState, createOrFocusTab, dispatch, requestId]);
  useEffect(() => {
    if (
      addDealFromParsedDocumentState !== undefined &&
      addDealFromParsedDocumentState.status === "success" &&
      addDealFromParsedDocumentState.data !== null
    ) {
      lockEntityFunction(
        dispatch,
        addDealFromParsedDocumentState.data.message._id,
        "deal",
        requestId
      );
      createOrFocusTab({
        label: "Show page",
        index: "showPage",
        isForSidebar: false,
        isForQuickAccess: false,
        isPersistent: false,
        props: {
          _id: addDealFromParsedDocumentState.data.message._id,
          type: "deal"
        }
      });
    }
  }, [addDealFromParsedDocumentState, createOrFocusTab, dispatch, requestId]);
  const columns: Column<DealData>[] = [
    {
      getData: (entry): CellValue =>
        [
          entry.data?.applicant
            ? `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
                entry.data?.applicant?.data?.info?.lastName ?? ""
              }`
            : undefined,
          entry.data?.coApplicant
            ? `${entry.data?.coApplicant?.data?.info?.firstName ?? ""} ${
                entry.data?.coApplicant?.data?.info?.lastName ?? ""
              }`
            : undefined
        ]
          .filter((x) => x)
          .join(" / "),
      label: "Customer",
      name: "customer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.customer?.show;
      },
      filters: [
        {
          path: [
            ["data", "applicant", "data", "info", "firstName"],
            ["data", "applicant", "data", "info", "lastName"],
            ["data", "coApplicant", "data", "info", "firstName"],
            ["data", "coApplicant", "data", "info", "lastName"]
          ],
          preview: ApplicantFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.refNumber,
      label: "Ref #",
      options: {
        sort: true,
        path: ["data", "info", "refNumber"]
      },
      name: "refNumber",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.refNumber?.show;
      },
      filters: [
        {
          path: ["data", "info", "refNumber"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.dealership?.data?.info?.displayName ?? "",
      label: "Dealership",
      name: "dealership",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealership?.show;
      },
      filters: [
        {
          path: ["data", "dealershipId"],
          preview: DealershipFilter
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.info?.extractedCreditScores?.applicant?.score} ${
          entry.data?.info?.extractedCreditScores?.coApplicant?.score
            ? "/ " + entry.data?.info?.extractedCreditScores?.coApplicant?.score
            : ""
        }`,
      label: "Credit Scores",
      name: "creditScores",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.creditScores?.show;
      },
      filters: []
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
      label: "Make",
      name: "make",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.make?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "make"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.model,
      label: "Model",
      name: "model",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.model?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "model"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.VIN,
      label: "VIN",
      name: "vin",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.vin?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "VIN"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
      label: "Lender",
      name: "lender",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.lender?.show;
      },
      filters: [
        {
          path: ["data", "lenderId"],
          preview: LenderFilter
        }
      ]
    },
    {
      options: {
        customBodyRender: (x: CellValue, deal): JSX.Element | string => {
          const prediction = deal?.data?.info?.prediction;
          if (prediction) {
            const probability = prediction?.decision?.probability
              ? (prediction?.decision?.probability * 100).toFixed(2)
              : 0;

            return (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">{`Prediction: ${capitalize(
                      prediction?.decision?.label
                    )}`}</Typography>
                    <em>{"Probability: "}</em> <b>{probability}%</b>
                  </React.Fragment>
                }
              >
                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                  <Typography color="inherit" variant="subtitle2">
                    {capitalize(prediction?.decision?.label) ?? "-"}
                  </Typography>
                  <PredictionIcon prediction={prediction} />
                </div>
              </HtmlTooltip>
            );
          }
          return (
            <Typography
              color="inherit"
              variant="subtitle2"
              style={{ justifyContent: "center", display: "flex" }}
            >
              -
            </Typography>
          );
        },
        sort: false,
        path: ["data", "info", "status"]
      },
      getData: (entry): CellValue => {
        const prediction = entry.data?.info.prediction;
        if (prediction) return capitalize(prediction.decision.label);

        return "-";
      },

      label: "Prediction",
      name: "prediction",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.prediction?.show;
      }
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.user?.data?.info?.firstName ?? ""} ${
          entry.data?.user?.data?.info?.lastName ?? ""
        }`,
      label: "F&I mgr",
      name: "f&iManager",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.["f&iManager"]?.show;
      },
      filters: [
        {
          path: ["data", "userId"],
          preview: UserFilter,
          type: "f&i manager"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.dealership?.data?.representative?.data?.info?.firstName ?? ""} ${
          entry.data?.dealership?.data?.representative?.data?.info?.lastName ?? ""
        }`,
      label: "Rep",
      name: "rep",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.rep?.show;
      },
      filters: [
        {
          path: ["data", "userId"],
          preview: UserFilter,
          type: "representative"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.status,
      label: "Status",
      options: {
        customBodyRender: (x: CellValue, deal): JSX.Element | string => {
          const dealStatus = x as DealStatus;
          return statusToChipTooltip(dealStatus, deal, getStyleByStatus(dealStatus));
        },
        sort: true,
        path: ["data", "info", "status"]
      },
      name: "status",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.status?.show;
      },
      filters: [
        {
          path: ["data", "info", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: [DealStatus.Lead, DealStatus.CreditCheck],
          optionLabelForSelect: (status) => capitalize(status)
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.payment?.fundedAmount ?? 0,
      label: "Funded",
      name: "fundedAmount",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.fundedAmount?.show;
      },
      filters: [
        {
          path: ["data", "info", "payment", "fundedAmount"],
          preview: PriceFilter
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.dealDates?.fundedAt
          ? new Date(entry.data?.info?.dealDates?.fundedAt)
          : "",
      label: "Funded At",
      options: {
        sort: true,
        path: ["data", "info", "dates", "fundedAt"]
      },
      name: "fundedAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.fundedAt?.show;
      },
      filters: [
        {
          path: ["data", "info", "dealDates", "fundedAt"],
          preview: DateFilter,
          label: "Funded At"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry._id,
      label: "Actions",
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      <AccessControl
        requiredPermissions={[
          { entity: "deal", action: "read" },
          { entity: "deal", action: "menu" }
        ]}
      >
        <Table
          tableName="leads"
          entityName="deal"
          listFunction={getDealList}
          listEntity={{
            ...dealList,
            entities: dealList?.entities?.filter((deal) => {
              if (
                [DealStatus.Lead, DealStatus.CreditCheck, DealStatus.DidNotProcess].includes(
                  deal.data.info.status
                )
              ) {
                return true;
              }
              // if (deal?.data?.info?.status === DealStatus.DidNotProcess) {
              //   return (
              //     typeof deal?.data?.user?._id !== "string" &&
              //     typeof deal?.data?.info?.creditCheckDate !== "string"
              //   );
              // }
              return false;
            })
          }}
          rowsPerPage={200}
          deleteEntityFunction={deleteDeal}
          addEntityFunction={addEntityFunction(dispatch, requestId)}
          addEntityFromDocumentFunction={addEntityFromDocumentFunction(dispatch, requestId)}
          recoverEntityFunction={recoverDeal}
          sort={{ createdAt: "desc" }}
          slice={"leads"}
          title={"Leads log"}
          columns={columns}
          query={{
            "data.info.status": {
              $regex: `^(${DealStatus.Lead}|${DealStatus.CreditCheck}|${DealStatus.DidNotProcess})$`
            }
          }}
          projection={projections}
          hideAddButtonByDocument={false}
        />
      </AccessControl>
    </div>
  );
}
