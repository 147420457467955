import { Dealership } from "./types";

function dateRangeOverlaps(a_start: number, a_end: number, b_start: number, b_end: number) {
  if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
  if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
  if (b_start <= a_start && a_end <= b_end) return true; // a in b
  return false;
}

function multipleDateRangeOverlaps(
  timeIntervals: Dealership["data"]["info"]["dealershipPrograms"]
) {
  if (!timeIntervals) return false;
  let i = 0,
    j = 0;

  if (timeIntervals != null && timeIntervals.length > 1)
    for (i = 0; i < timeIntervals.length - 1; i += 1) {
      for (j = i + 1; j < timeIntervals.length; j += 1) {
        const iStartDate = new Date(timeIntervals[i].startDate || 0);
        const iEndDate = new Date(timeIntervals[i].endDate || 99999999999999);
        const jStartDate = new Date(timeIntervals[j].startDate || 0);
        const jEndDate = new Date(timeIntervals[j].endDate || 99999999999999);
        iStartDate.setHours(0);
        iStartDate.setMinutes(0);
        iStartDate.setMilliseconds(0);
        jStartDate.setHours(0);
        jStartDate.setMinutes(0);
        jStartDate.setMilliseconds(0);
        iEndDate.setHours(0);
        iEndDate.setMinutes(0);
        iEndDate.setMilliseconds(0);
        jEndDate.setHours(0);
        jEndDate.setMinutes(0);
        jEndDate.setMilliseconds(0);
        if (
          dateRangeOverlaps(
            iStartDate.getTime(),
            iEndDate.getTime(),
            jStartDate?.getTime(),
            jEndDate?.getTime()
          )
        )
          return true;
      }
    }
  return false;
}

export const validate = (dealership: Dealership): { valid: boolean; message: string } => {
  const overlapseResult = multipleDateRangeOverlaps(dealership.data.info.dealershipPrograms);
  return { valid: !overlapseResult, message: "You should not have periods that overlapse!" };
};
