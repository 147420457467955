import { AdditionalRequiredDocument } from "components/AdditionalRequiredDocuments/types";
import { ContractType } from "components/Contracts/ContractTypes/types";
import { Dealership } from "components/Dealerships/types";
import { Deal, collateralTypes, CollateralTypes } from "components/Deals/types";
import { Content } from "components/PivotTable/types";
import { User } from "components/Users/types";
import { getTabName, typedKeys } from "utils/functions";
import { Path, TimelineItem } from "utils/models/fields";
import { AddRequest, CrudEntity, Many, RequestSend } from "utils/types";

export type Lender = CrudEntity & { data: FormState };

export interface FormState {
  info: Info;
  representatives: Representative[];
  requirements: Requirement[];
  notes: Note[];
  requiredContractTypesIds: string[];
  requiredContractTypes: ContractType[];
  additionalRequiredDocumentsIds: string[];
  additionalRequiredDocuments: AdditionalRequiredDocument[];
  netsuiteId?: string;
  roundToDollar?: boolean;
  currentUser?: User;
  oldOperatingStates?: {
    comment?: string;
    status: OperatesInStateStatus;
    states?: OperatingStates[];
  };
}

export interface OtherInfoStruct {
  info: string;
}

type Routes = {
  [key: string]: {
    [key: string]: {
      isAvailable: boolean;
      partyId?: string;
    };
  };
};
export interface CostRequirement {
  criteria: string;
  type: string;
  vertical?: Reserves;
  child?: Reserves;
  content?: Content;
}
export interface Reserves {
  name: string;
  path: Path<Deal>;
  requirements: CostRequirement[];
}

export enum DealTypeToReserve {
  Automotive = "automotive",
  Marine = "marine",
  "Recreational Vehicles" = "rv",
  "Power Sport" = "powerSport"
}

export interface ReserveData {
  name: string;
  startDate: string;
  endDate: string;
  priority: number;
  reserves: { "fulltimeF&I": Reserves; correspondent: Reserves };
}

export type Period = {
  month: number;
  year: number;
  day: number;
};
export type AllocationPeriod =
  | MonthlyAllocationPeriod
  | YearlyAllocationPeriod
  | LifetimeAllocationPeriod;
export interface MonthlyAllocationPeriod {
  from: Period;
  to: Period;
  allocation?: number;
  softAllocation?: number;
  suggested: boolean;
}
export type YearlyAllocationPeriod = {
  from: Period;
  to: Period;
  allocation?: number;
  softAllocation?: number;
  suggested: boolean;
};
export type LifetimeAllocationPeriod = {
  runoff?: Runoff[];
  seed: Seed;
  to?: Period;
  from: Period;
  allocation: number;
  softAllocation?: number;
  suggested: boolean;
};
export type Seed = {
  amount: number;
  date: Date;
};
export type Runoff = Period & {
  amount: number;
};
export interface ReservesTypes {
  automotive: ReserveData[];
  marine: ReserveData[];
  rv: ReserveData[];
  powerSport: ReserveData[];
}
export interface AdditionalAddress {
  address: string;
  state: string;
  city: string;
  zipCode: string;
  name: string;
  securedPartyNumber: string;
}
export interface Info {
  allocationDashboardSettings?: {
    dealDateToUse: "submittedOrFundedDate" | "contractDate";
  };
  allocation?: boolean;
  //allocationPeriods?: (MonthlyAllocationPeriod | YearlyAllocationPeriod | YearlyAllocationPeriod)[];
  allocationType: "monthly" | "yearly" | "lifetime";
  monthlyAllocationPeriods?: MonthlyAllocationPeriod[];
  yearlyAllocationPeriods?: YearlyAllocationPeriod[];
  lifetimeAllocationPeriods?: LifetimeAllocationPeriod[];
  coordinates?: {
    lat: number;
    lon: number;
  };
  eSign: boolean;
  esignAuth?: {
    sms?: boolean;
    kba?: boolean;
  };
  requestCreditScoreReport: boolean;
  name: string;
  securedPartyNumber: string;
  active: boolean;
  address: string;
  state: string;
  city: string;
  county?: string;
  zipCode: string;
  additionalAddresses: AdditionalAddress[];
  phone: string;
  faxNumber: string;
  email: string;
  otherInfo: OtherInfoStruct[];
  idNumber: string;
  defaultReservePercent: string;
  defaultBuyRate: string;
  recapCalcMethod: number;
  statePrefix: string;
  nameAndFullAddress: string;
  coverage: Coverage | undefined;
  reserves: ReservesTypes;
  markupTables: MarkupTable[];
  specificRouteMethod: boolean;
  routeDescription?: string;
  routes: Routes;
  collateralTypes: Record<typeof collateralTypes[number], boolean>;
  adminEmail?: string;
  adminFirstName?: string;
  adminLastName?: string;
  adminRegistrationLink?: string;
  isPlatform?: boolean;
  aliases?: { alias: string }[];
  statusHistory: LenderStatusHistory[];
  operatingStates?: {
    comment?: string;
    status: OperatesInStateStatus;
    states?: OperatingStates[];
  };
}
export type LenderStatusHistory = {
  status: OperatesInStateStatus;
  comment: string;
  date: string;
  userEmail: string;
  oldData?: {
    comment?: string;
    status: OperatesInStateStatus;
    states?: OperatingStates[];
  };
  newData: {
    comment?: string;
    status: OperatesInStateStatus;
    states?: OperatingStates[];
  };
};
export type MarkupType = "correspondent" | "fulltimeF&I";
export interface MarkupTable {
  id: string;
  table: Reserves;
  name: string;
  markupType: MarkupType;
}
export interface Coverage {
  collisionDeductible: number;
  comprehensiveDeductible: number;
  collisionX: boolean;
  comprenhensiveX: boolean;
  "collisionDeductibleOrN/A": number | string;
  "comprehensiveDeductibleOrN/A": number | string;
}
export interface Representative {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

export interface RepresentativeProps {
  state: Representative[];
  setState?: (value: Representative[]) => void;
}

export interface Requirement {
  requirement: string;
}

export interface Note {
  note: string;
}

export interface NoteProps {
  state: Note[];
  setState?: (value: Note[]) => void;
}

export enum OperatesInStateStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  STATE = "state-specific",
  COUNTY = "county-specific"
}
export enum SupportedDealershipStatus {
  ALL = "all",
  SPECIFIC = "specific"
}
export type OperatingCounties = {
  counties: string[];
  supportedDealerships: {
    status: SupportedDealershipStatus;
    ids: string[];
  };
};
export type OperatingStates = {
  state: string;
  status: OperatesInStateStatus;
  supportedDealerships: {
    status: SupportedDealershipStatus;
    ids?: string[];
  };
  inactiveInState?: boolean;
  operatesInSomeCounties?: boolean;
  operatingCounties?: OperatingCounties[];
  collateralTypes?: { [key in CollateralTypes]: boolean };
};

interface LenderRequest {
  _id: string;
  data: FormState;
  availableToRolesIds?: string[] | null;
}

type ExternalFields = Many<"additionalRequiredDocuments" | "requiredContractTypes">;
type ExternalFieldsIds = "additionalRequiredDocumentsIds" | "requiredContractTypesIds";

export type EditLenderRequest = LenderRequest;
export type AddLenderRequest = AddRequest<LenderRequest>;
export type EditLenderRequestSend = RequestSend<
  EditLenderRequest,
  ExternalFields,
  ExternalFieldsIds
>;
export type AddLenderRequestSend = RequestSend<AddLenderRequest, ExternalFields, ExternalFieldsIds>;

const getYearAhead = (startDate: string) => {
  const d = new Date(startDate);
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  return new Date(year + 1, month, day);
};
export const reserversToTimelineItems = (items: ReservesTypes): TimelineItem[] => {
  try {
    if (items) {
      const minMax = typedKeys(items).reduce(
        (acc, key: keyof ReservesTypes) => {
          Object.values(items[key])
            ?.flat()
            ?.forEach((item) => {
              const currMin = new Date(item?.startDate).getTime();
              const currMax = new Date(item?.endDate).getTime();

              acc.min = Math.max(acc.min, currMin);
              acc.max = Math.max(acc.max, currMax);
              acc.maxMin = Math.max(acc.maxMin, currMin);
            });
          return acc;
        },
        { min: Infinity, max: 0, maxMin: -Infinity }
      );

      return typedKeys(items).reduce((acc: TimelineItem[], key: keyof ReservesTypes) => {
        const currentItems = Object.values(items[key])?.flat()?.slice() ?? [];
        const sorted = Array.isArray(currentItems)
          ? currentItems?.sort((x, y) => y.priority - x.priority)
          : [];
        return [
          ...acc,
          ...sorted.map((el, index) => ({
            name: getTabName(el),
            data: [
              {
                x: key,
                index,
                y: [
                  el?.startDate ? new Date(el?.startDate).getTime() : new Date().getTime(),
                  el?.endDate
                    ? new Date(el?.endDate).getTime()
                    : getYearAhead(
                        new Date(Math.max(minMax.maxMin, minMax.max))?.toISOString()
                      ).getTime()
                ]
              }
            ]
          }))
        ];
      }, []);
    }
    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};
