import {
  Button,
  capitalize,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Big from "big.js";
import CreditScoreRangePreview from "components/CreditScoreRanges/CreditScoreRangePreview";
import { CreditScoreRange } from "components/CreditScoreRanges/types";
import { collateralTypes } from "components/Deals/types";
import { OperatesInStateStatus } from "components/Lenders/types";
import Spinner from "components/Loader/Spinner";
import useLenderCreditStatistics, {
  CreditScores,
  LenderCreditStatisticsResponse
} from "hooks/useLenderDecisionStatistics/useLenderDecisionStatistics";
import { useStickyState } from "index";
import React, { useState } from "react";
import States from "us-states";
import {
  formatNumberAsCurrency,
  getFirstAndLastDateOfAYear,
  getStateLabelByState
} from "utils/functions";
import { createOptionsForSelect } from "utils/models/fields";
import { editRenderSet } from "utils/models/formRenderers";
import DateFilterLenderDecisions, { DateState } from "./DateFilterLenderDecisions";
import "./LenderDecisionsStatistics.css";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    width: 400,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

const LenderCreditStatisticsTable = ({
  hideTitle,
  setResetStickyCallback
}: {
  hideTitle?: boolean;
  setResetStickyCallback?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
}) => {
  const [states, setStates] = useStickyState<string[]>(
    ["Minnesota"],
    "lender_credit_statistics_states"
  );
  const [filter, setFilter] = useStickyState<"active" | "inactive">(
    "active",
    "lender_credit_statistics_filter"
  );

  const [creditScoreRange, setCreditScoreRange] = useStickyState<CreditScoreRange | null>(
    null,
    "lender_credit_statistics_creditScoreRange"
  );
  const [dateFilter, setDateFilter] = useStickyState<DateState>(
    getFirstAndLastDateOfAYear(new Date().getFullYear()),
    "lender_credit_statistics_dateFilter"
  );

  const stateAccess = {
    get: () => states,
    set: (path: string, value: string[]): any => setStates(value)
  };
  const stateAccessFilter = {
    get: () => filter,
    set: (path: string, value: "active" | "inactive"): any => setFilter(value)
  };
  const { data, isLoading, isFetching } = useLenderCreditStatistics({
    creditScoreRangeId: creditScoreRange?._id,
    states,
    filter,
    from: dateFilter?.from,
    to: dateFilter?.to
  });

  const scoreOrder = [
    ...(creditScoreRange?.data?.info?.ranges.map((range) => ({
      score: range.name,
      label: `${range.name} ${range.minimumScore}-${range.maximumScore}`
    })) ?? []),
    { score: "noScore", label: "No score" }
  ];
  const handleResetStickyState = () => {
    setStates(["Minnesota"]);
    setFilter("active");
    setCreditScoreRange(null);
    setDateFilter(getFirstAndLastDateOfAYear(new Date().getFullYear()));
  };
  React.useEffect(() => {
    setResetStickyCallback?.(() => handleResetStickyState);
  }, []);
  return (
    <Grid container>
      {setResetStickyCallback === undefined && (
        <Button
          onClick={() => handleResetStickyState()}
          style={{ margin: "5px 0px", float: "right" }}
          color="primary"
          variant="contained"
        >
          Reset state
        </Button>
      )}
      <Grid
        spacing={1}
        container
        justifyContent="space-between"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Grid item xs={3}>
          {!hideTitle && (
            <Typography style={{ fontSize: 20, fontWeight: 500, margin: 5 }}>
              Lender State Coverage
            </Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <DateFilterLenderDecisions setFilter={setDateFilter} filter={dateFilter} />
        </Grid>
        <Grid item xs={2}>
          {editRenderSet(false).selectInputRenderer(
            {
              formComponent: "select-field",
              name: "Active/Inactive filter",
              label: "Lender Status",
              path: [],
              options: createOptionsForSelect({
                possibleValues: () => ["active", "inactive"],
                getOptionLabel: (x) => capitalize(x),
                getSelectedOption: (x, y) => x === y
              })
            },
            stateAccessFilter,
            [],
            stateAccessFilter,
            editRenderSet(false)
          )}
        </Grid>
        <Grid item xs={2}>
          {editRenderSet(false).multiSelectInputRenderer(
            {
              formComponent: "multiselect-field",
              name: "States",
              label: "States",
              path: [],
              options: createOptionsForSelect({
                possibleValues: () => Object.keys(States),
                getOptionLabel: (x) => getStateLabelByState(x),
                getSelectedOption: (x, y) => x === y
              }),
              required: true
            },
            stateAccess,
            [],
            stateAccess,
            editRenderSet(false)
          )}
        </Grid>
        <Grid item xs={2}>
          <CreditScoreRangePreview
            selectedRange={creditScoreRange}
            setCreditScoreRange={setCreditScoreRange}
          />
        </Grid>
        {isFetching ? (
          <Spinner text={"Loading "} style={{ position: "relative" }} />
        ) : data && Object.keys(data).length ? (
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Grid container alignItems="center" style={{ margin: "10px 0px", gap: 5 }}>
              <InfoIcon color="primary" fontSize="medium" />
              <Typography variant="h6">
                {filter === "active"
                  ? "Displaying lenders currently active in the system and those who have made decisions during the period."
                  : "Displaying lenders marked as inactive in the system."}
              </Typography>
            </Grid>
            <TableContainer>
              {Object.keys(data).map((state: keyof LenderCreditStatisticsResponse) => (
                <div key={state} className="state-section">
                  <Typography variant="h6" className="state-header">
                    {state}
                  </Typography>
                  <Table size="small" className="state-table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ maxWidth: "10%" }} className="score-header">
                          Score Type
                        </TableCell>
                        {collateralTypes.map((type) => (
                          <TableCell className="score-header" key={type}>
                            {type}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {scoreOrder?.map(({ score, label }) => (
                        <TableRow key={score}>
                          <TableCell className="score-header">{label}</TableCell>
                          {collateralTypes.map((type) => {
                            const lenderData = (data?.[state]?.[type] as CreditScores)?.[
                              score as keyof CreditScores
                            ];
                            const total =
                              lenderData?.lenders?.reduce((acc, curr) => acc + curr.total, 0) || 0;

                            return (
                              <TableCell key={type}>
                                <div className="scores-container">
                                  {lenderData?.totalLendersCount ? (
                                    <div className="total-decisions">{`Total: ${total}/Approved: ${lenderData?.totalLendersCount}`}</div>
                                  ) : (
                                    <div className="no-decisions">{`No decisions `}</div>
                                  )}
                                  {lenderData?.lenders
                                    ?.sort((a, b) => b.total - a.total)
                                    ?.map((lender, index: number) => {
                                      const lookedToApproved = lender.total
                                        ? new Big(lender.approvedTotal || 0)
                                            .div(new Big(lender.total))
                                            .mul(new Big(100))
                                            .toNumber()
                                        : 0;
                                      return (
                                        <HtmlTooltip
                                          key={index}
                                          title={
                                            <React.Fragment>
                                              <Typography color="inherit" variant="subtitle2">
                                                {lender.name}
                                                {lender?.status &&
                                                lender?.status !==
                                                  OperatesInStateStatus.INACTIVE ? (
                                                  <>
                                                    <VerifiedUserIcon
                                                      fontSize="inherit"
                                                      style={{ color: "#52a543" }}
                                                    />
                                                    {lender.status === OperatesInStateStatus.COUNTY
                                                      ? "*"
                                                      : ""}{" "}
                                                    (Active)
                                                  </>
                                                ) : lender.status === "inactive" ? (
                                                  <>
                                                    <RemoveCircleIcon
                                                      fontSize="inherit"
                                                      color="secondary"
                                                    />{" "}
                                                    (Inactive)
                                                  </>
                                                ) : null}
                                              </Typography>

                                              <Typography variant="body2" color="inherit">
                                                {`Approved: ${lender.approvedTotal}`}
                                              </Typography>
                                              <Typography variant="body2" color="inherit">
                                                {`Conditionally approved: ${lender.conditionallyApprovedTotal}`}
                                              </Typography>
                                              <Typography variant="body2" color="inherit">
                                                {`Declined: ${lender.declinedTotal}`}
                                              </Typography>
                                              <Typography variant="body2" color="inherit">
                                                {`Other(Pending, Transmitting): ${lender.otherTotal}`}
                                              </Typography>
                                              <Typography variant="body2" color="inherit">
                                                {`Total: ${lender.total}`}
                                              </Typography>
                                              <Typography variant="body2" color="inherit">
                                                {`Approved to Looked: ${lookedToApproved.toFixed(
                                                  2
                                                )}%`}
                                              </Typography>
                                              <hr />
                                              <Typography variant="body2" color="inherit">
                                                {`Funded: ${lender.fundedTotal}`}
                                              </Typography>
                                              {/* {lender.approvedTotal > 0 ? (
                                                <Typography variant="body2" color="inherit">
                                                  {`Funded to Approved: ${new Big(
                                                    lender.fundedTotal || 0
                                                  )
                                                    .div(new Big(lender.approvedTotal))
                                                    .mul(new Big(100))
                                                    .toFixed(2)}%`}
                                                </Typography>
                                              ) : null} */}
                                              <Typography variant="body2" color="inherit">
                                                {`Amount financed: ${formatNumberAsCurrency(
                                                  lender.totalFundedAmount,
                                                  "$"
                                                )}`}
                                              </Typography>
                                            </React.Fragment>
                                          }
                                        >
                                          <p
                                            className="lender score"
                                            style={{
                                              backgroundColor:
                                                lookedToApproved >= 40
                                                  ? "#006400"
                                                  : lookedToApproved === 0
                                                  ? "#8B0000"
                                                  : "#FF8C00"
                                            }}
                                          >
                                            {`${lender.name}`}- {lender.total}
                                            {lender?.status &&
                                            lender?.status !== OperatesInStateStatus.INACTIVE ? (
                                              <>
                                                <VerifiedUserIcon fontSize="inherit" />
                                                {lender.status === OperatesInStateStatus.COUNTY
                                                  ? "*"
                                                  : ""}
                                              </>
                                            ) : lender.status === OperatesInStateStatus.INACTIVE ? (
                                              <RemoveCircleIcon fontSize="inherit" />
                                            ) : null}
                                          </p>
                                        </HtmlTooltip>
                                      );
                                    })}
                                </div>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ))}
            </TableContainer>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5">Legend</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  <VerifiedUserIcon fontSize="inherit" style={{ color: "#52a543" }} /> - The lender
                  operates in this state and with this collateral type.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  <RemoveCircleIcon fontSize="inherit" color="secondary" /> - The lender does{" "}
                  <b>NOT</b> operate in this state and with this collateral type.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  <b>*</b> - Operates only in certain counties.
                </Typography>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={4}>
                  <Typography variant="h6">
                    Color Codes for Approved to Looked Percentages -
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6">
                    <p
                      className="lender score"
                      style={{
                        justifyContent: "center",
                        backgroundColor: "#006400"
                      }}
                    >
                      40% and above
                    </p>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6">
                    <p
                      className="lender score"
                      style={{
                        justifyContent: "center",
                        backgroundColor: "#FF8C00"
                      }}
                    >
                      0-39%
                    </p>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6">
                    <p
                      className="lender score"
                      style={{
                        justifyContent: "center",
                        backgroundColor: "#8B0000"
                      }}
                    >
                      0%
                    </p>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : data ? (
          <Grid
            item
            xs={12}
            style={{ display: "flex", alignContent: "center", justifyContent: "center" }}
          >
            <Typography style={{ fontSize: 26, fontWeight: 500, margin: 5 }}>
              No lender decisions were found based on the provided terms.
            </Typography>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            style={{ display: "flex", alignContent: "center", justifyContent: "center" }}
          >
            <Typography style={{ fontSize: 26, fontWeight: 500, margin: 5 }}>
              Please fill in the fields to continue.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default LenderCreditStatisticsTable;
