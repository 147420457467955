import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { RootState } from "app/rootReducer";
import CloseDialogButton from "components/common/CloseDialogButton";
import {
  getStyleByStatus,
  projections,
  statusToChipTooltip,
  statusToLabel
} from "components/Deals/Deals";
import { moveDate } from "components/Deals/DealsLog/DealsLog";
import { getDealList } from "components/Deals/listDealSlice";
import { DealData, DealStatus } from "components/Deals/types";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DateFilter from "components/Filters/DateFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import PriceFilter from "components/Filters/PriceFilter";
import TextFilter from "components/Filters/TextFilter";
import UserFilter from "components/Filters/UserFilter";
import Table, { CellValue, Column } from "components/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lockEntityFunction, unlockEntityFunction } from "utils/models/ShowForm";
import { v4 as uuidv4 } from "uuid";
import { editLenderDecision } from "./editLenderDecisionSlice";
import { LenderDescision } from "./types";
export default function ({
  lenderDecision,
  setTableDialog,
  onlyRefNumbers
}: {
  lenderDecision: LenderDescision;
  setTableDialog: any;
  onlyRefNumbers?: (string | undefined)[];
}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.authSlice);
  const [date] = useState(moveDate(new Date(), -31));
  const [decision] = useState(lenderDecision);
  const [requestId] = useState(uuidv4());
  const editLenderDecisionState = useSelector(
    (state: RootState) => state?.editLenderDecisionSlice[requestId]
  );
  const [openWarningLocked, setOpenWarningLocked] = useState(false);

  const lockData = useSelector((state: RootState) =>
    state.listLockSlice["all"]?.entities?.find(
      (lock) =>
        lock?.data?.info?.entityId === decision?._id &&
        lock?.data?.info?.entityName === "lender_decision"
    )
  );
  const lenderDecisionId = decision._id;
  const slice = `lender-decision-deals-${lenderDecisionId}-${
    onlyRefNumbers ? "onlyRefNumbers" : ""
  }`;

  const dealList = useSelector((state: RootState) => state.listDealSlice[slice]);

  const currentlyLockedEmail = lockData?.data?.info?.userEmail ?? null;
  const canEdit = user?.email === currentlyLockedEmail;
  const isLockedByAnotherUser = currentlyLockedEmail !== null && !canEdit;

  const handleClickUnlock = () => {
    lockEntityFunction(dispatch, lenderDecisionId, "lender_decision");
    setOpenWarningLocked(false);
    setTableDialog(true);
  };

  const handleClose = () => {
    setOpenWarningLocked(false);
    setTableDialog(false);
    unlockEntityFunction(dispatch, lenderDecisionId, "lender_decision");
  };
  const handleCloseTableDialog = () => {
    setTableDialog(false);
    setOpenWarningLocked(false);
    unlockEntityFunction(dispatch, lenderDecisionId, "lender_decision");
  };

  useEffect(() => {
    if (editLenderDecisionState?.status === "success") {
      unlockEntityFunction(dispatch, lenderDecisionId, "lender_decision");
      setTableDialog(false);
    }
  }, [editLenderDecisionState, lenderDecisionId, dispatch, setTableDialog]);

  useEffect(() => {
    if (isLockedByAnotherUser) setOpenWarningLocked(true);
    else lockEntityFunction(dispatch, lenderDecisionId, "lender_decision");
  }, [isLockedByAnotherUser, lenderDecisionId, dispatch]);

  const columns: Column<DealData>[] = [
    {
      getData: (entry): CellValue => entry.data?.info?.refNumber,
      label: "Ref #",
      options: {
        sort: true,
        path: ["data", "info", "refNumber"]
      },
      name: "refNumber",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.refNumber?.show;
      },
      filters: [
        {
          path: ["data", "info", "refNumber"],
          preview: TextFilter,
          name: "refNumber",
          label: "Ref Number"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
          entry.data?.applicant?.data?.info?.lastName ?? ""
        }`,
      label: "Customer",
      name: "customer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.customer?.show;
      },
      filters: [
        {
          path: [
            ["data", "applicant", "data", "info", "firstName"],
            ["data", "applicant", "data", "info", "lastName"]
          ],
          preview: ApplicantFilter,
          caseInsensitive: true,
          name: "customer",
          label: "Customer"
        }
      ]
    },

    {
      getData: (entry): CellValue => entry.data?.dealership?.data?.info?.name ?? "",
      label: "Dealership",
      name: "dealership",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealership?.show;
      },
      filters: [
        {
          path: ["data", "dealershipId"],
          preview: DealershipFilter,
          name: "dealership",
          label: "Dealership"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
      label: "Make",
      name: "make",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.make?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "make"],
          preview: TextFilter,
          partialSearch: true,
          caseInsensitive: true,
          name: "make",
          label: "Make"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.model,
      label: "Model",
      name: "model",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.model?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "model"],
          preview: TextFilter,
          partialSearch: true,
          caseInsensitive: true,
          name: "model",
          label: "Model"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
      label: "Lender",
      name: "lender",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.lender?.show;
      },
      filters: [
        {
          path: ["data", "lenderId"],
          preview: LenderFilter,
          name: "lender",
          label: "Lender"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.user?.data?.info?.firstName ?? ""} ${
          entry.data?.user?.data?.info?.lastName ?? ""
        }`,
      label: "F&I mgr",
      name: "f&iManager",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.["f&iManager"]?.show;
      },
      filters: [
        {
          path: ["data", "userId"],
          preview: UserFilter,
          type: "f&i manager",
          name: "f&i mgr",
          label: "F&I mgr"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.dealership?.data?.representative?.data?.info?.firstName ?? ""} ${
          entry.data?.dealership?.data?.representative?.data?.info?.lastName ?? ""
        }`,
      label: "Rep",
      name: "rep",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.rep?.show;
      },
      filters: [
        {
          path: ["data", "dealership", "data", "representativeId"],
          preview: UserFilter,
          type: "representative",
          name: "rep",
          label: "Rep"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.status,
      label: "Status",
      options: {
        customBodyRender: (x: CellValue, deal): JSX.Element | string => {
          const dealStatus = x as DealStatus;
          return statusToChipTooltip(dealStatus, deal, getStyleByStatus(dealStatus));
        },
        sort: true,
        path: ["data", "info", "status"]
      },
      name: "status",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.status?.show;
      },
      filters: [
        {
          path: ["data", "info", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: Object.values(DealStatus),
          optionLabelForSelect: (status) => statusToLabel(status)
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.payment?.fundedAmount ?? 0,
      label: "Funded",
      name: "fundedAmount",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.fundedAmount?.show;
      },
      filters: [
        {
          name: "fundedAmount",
          label: "Funded Amount",
          path: ["data", "info", "payment", "fundedAmount"],
          preview: PriceFilter
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.dealDates?.fundedAt
          ? new Date(entry.data?.info?.dealDates?.fundedAt)
          : "",
      label: "Funded At",
      options: {
        sort: true,
        path: ["data", "info", "dates", "fundedAt"]
      },
      name: "fundedAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.fundedAt?.show;
      },
      filters: [
        {
          path: ["data", "info", "dealDates", "fundedAt"],
          preview: DateFilter,
          label: "Funded At",
          name: "fundedAt"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry?._id,
      label: "Actions",
      options: {
        customBodyRender: (_x: CellValue, deal): JSX.Element | string => (
          <div style={{ whiteSpace: "nowrap", textAlign: "right" }}>
            <IconButton
              color="primary"
              size="small"
              onClick={() => {
                dispatch(
                  editLenderDecision({
                    _id: lenderDecisionId,
                    data: {
                      ...decision.data,
                      refNumber: deal.data.info.refNumber,
                      state:
                        deal?.data?.applicant?.data?.info?.currentState ||
                        deal?.data?.applicant?.data?.info?.drivingLicenseState,
                      county: deal?.data?.applicant?.data?.info?.currentCounty,
                      dealershipId:
                        deal?.data?.dealership?._id || deal?.data?.dealershipId || undefined,
                      dealId: deal._id,
                      possibleDeals: onlyRefNumbers
                        ? [{ refNumber: deal.data.info.refNumber, dealId: deal._id }]
                        : decision.data.possibleDeals
                    },
                    requestId
                  })
                );
              }}
            >
              <Tooltip title={"Select deal"}>
                <AddIcon />
              </Tooltip>
            </IconButton>
          </div>
        )
      },
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <>
      <Dialog
        id="confirm-delete-dialog"
        open={openWarningLocked}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to unlock this lender decision?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {lockData?.updatedAt &&
              `Locked by ${lockData?.data?.info?.userEmail}
       ${new Intl.DateTimeFormat("en", {
         day: "2-digit",
         month: "2-digit",
         year: "2-digit",
         hour: "2-digit",
         minute: "2-digit",
         second: "2-digit"
       }).format(new Date(lockData?.updatedAt))}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary" id="cancel">
            Cancel
          </Button>
          <Button
            onClick={handleClickUnlock}
            variant="contained"
            color="secondary"
            autoFocus
            id="confirm"
          >
            Unlock
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth onClose={handleCloseTableDialog} open={!openWarningLocked} maxWidth="xl">
        <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
          <Grid item xs={6} key="title">
            <DialogTitle>
              <CloseDialogButton closeFunction={handleCloseTableDialog} />
            </DialogTitle>
          </Grid>
        </Grid>
        <DialogContent>
          {canEdit ? (
            <Table
              tableName="lender_decisions_table_deal_preview"
              entityName="deal"
              listFunction={
                onlyRefNumbers
                  ? () =>
                      getDealList(slice, {
                        query: {
                          "data.info.refNumber": { $in: onlyRefNumbers }
                        }
                      })
                  : getDealList
              }
              listEntity={dealList}
              slice={slice}
              sort={{ createdAt: "desc" }}
              title={"Deals"}
              query={{ createdAt: { $gte: date } }}
              columns={columns}
              projection={{ ...projections, "data.dealership._id": 1, "data.dealershipId": 1 }}
              hideDeleteButton={true}
              hideAddButton={true}
              hideRecoverButton={true}
              dateFilters={[
                { label: "Created At", path: ["createdAt"] },
                { label: "Updated At", path: ["updatedAt"] }
              ]}
            />
          ) : (
            <div id="loading" style={{ textAlign: "center", fontSize: "20px" }}>
              Loading...
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseTableDialog} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
