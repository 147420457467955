import { Button, Dialog, DialogContent, DialogTitle, Grid, Tooltip } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import Send from "@material-ui/icons/Send";
import StarRateIcon from "@material-ui/icons/StarRate";
import { RootState } from "app/rootReducer";
import { RVClasses, RVClassesValues } from "components/AppOne/Deal/model";
import AddEntity from "components/Content/addEntity";
import formEditContext from "components/Content/FormEditContext";
import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEntity } from "utils/entitySlice";
import { setByPath, StateAccess } from "utils/models/formGenerator";
import { getPrefixByState } from "../../utils/functions";
import { EmploymentStatus, Info as ApplicantInfo, PropertyOwnership } from "../Applicants/types";
import AlertDialog from "../common/AlertDialog";
import CloseDialogButton from "../common/CloseDialogButton";
import modalContainerContext from "../Content/modalContainer";
import { Deal, Payment, Price, TaxesAndFees, Vehicle } from "./types";

interface Props {
  stateAccess: StateAccess;
  isDefault?: boolean;
}

export const initializeAppOneData = ({ _id, data: dealData }: Deal) => (initialState: any) => {
  const appOneData = dealData?.appOneData ?? null;
  if (appOneData !== null) {
    return appOneData;
  }

  const applicantInfo: ApplicantInfo | null = dealData?.applicant?.data?.info ?? null;
  const coApplicantInfo: ApplicantInfo | null = dealData?.coApplicant?.data?.info ?? null;
  const vehicle: Vehicle | null = dealData?.info?.vehicle ?? null;
  const price: Price | null = dealData?.info?.price ?? null;
  const payment: Payment | null = dealData?.info?.payment ?? null;
  const taxesAndFees: TaxesAndFees | null = dealData?.info?.taxesAndFees ?? null;
  const unitStatus = vehicle?.trailer?.unitStatus ?? null;
  const trade = price.trade ?? null;
  return [
    { value: _id, path: ["dealId"] },
    { value: dealData?.info?.refNumber ?? null, path: ["refNumber"] },
    {
      value: coApplicantInfo !== null,
      path: ["data", "Borrowers", "includeCoBorrower"]
    },
    {
      value: applicantInfo?.firstName ?? null,
      path: ["data", "Borrowers", "Borrower", "FirstName"]
    },
    {
      value: applicantInfo?.middleName ?? null,
      path: ["data", "Borrowers", "Borrower", "MiddleName"]
    },
    {
      value: applicantInfo?.lastName ?? null,
      path: ["data", "Borrowers", "Borrower", "LastName"]
    },
    {
      value: applicantInfo?.suffix ?? null,
      path: ["data", "Borrowers", "Borrower", "Suffix"]
    },
    {
      value: applicantInfo?.socialSecurityNumber ?? null,
      path: ["data", "Borrowers", "Borrower", "SSN"]
    },
    {
      value: applicantInfo?.birthDate ?? null,
      path: ["data", "Borrowers", "Borrower", "DOB"]
    },
    {
      value: applicantInfo?.driverLicenseNumber ?? null,
      path: ["data", "Borrowers", "Borrower", "DLNo"]
    },
    {
      value: getPrefixByState(applicantInfo?.drivingLicenseState) ?? null,
      path: ["data", "Borrowers", "Borrower", "DLState"]
    },
    {
      value: applicantInfo?.email ?? null,
      path: ["data", "Borrowers", "Borrower", "Email"]
    },
    {
      value: applicantInfo?.homePhone ?? null,
      path: ["data", "Borrowers", "Borrower", "HomePhone"]
    },
    {
      value: applicantInfo?.mobilePhone ?? null,
      path: ["data", "Borrowers", "Borrower", "MobilePhone"]
    },
    {
      value: defaultAddress(applicantInfo),
      path: ["data", "Borrowers", "Borrower", "Addresses", "Address"]
    },
    {
      value: defaultEmployment(applicantInfo),
      path: ["data", "Borrowers", "Borrower", "EmploymentInfo", "Employment"]
    },
    {
      value:
        (applicantInfo?.additionalIncomes ?? [])?.reduce(
          (acc, additionalIncome) => acc + (additionalIncome.value || 0),
          0
        ) ?? null,
      path: ["data", "Borrowers", "Borrower", "OtherIncome", "Amount"]
    },
    {
      value:
        (applicantInfo?.additionalIncomes ?? [])
          ?.filter((x) => x.source)
          ?.map((x) => x.source)
          .join(", ") ?? null,
      path: ["data", "Borrowers", "Borrower", "OtherIncome", "Description"]
    },

    //COAPPLICANT
    {
      value: coApplicantInfo?.firstName ?? null,
      path: ["data", "Borrowers", "CoBorrower", "FirstName"]
    },
    {
      value: coApplicantInfo?.middleName ?? null,
      path: ["data", "Borrowers", "CoBorrower", "MiddleName"]
    },
    {
      value: coApplicantInfo?.lastName ?? null,
      path: ["data", "Borrowers", "CoBorrower", "LastName"]
    },
    {
      value: coApplicantInfo?.suffix ?? null,
      path: ["data", "Borrowers", "CoBorrower", "Suffix"]
    },
    {
      value: coApplicantInfo?.socialSecurityNumber ?? null,
      path: ["data", "Borrowers", "CoBorrower", "SSN"]
    },
    {
      value: coApplicantInfo?.birthDate ?? null,
      path: ["data", "Borrowers", "CoBorrower", "DOB"]
    },
    {
      value: coApplicantInfo?.driverLicenseNumber ?? null,
      path: ["data", "Borrowers", "CoBorrower", "DLNo"]
    },
    {
      value: getPrefixByState(coApplicantInfo?.drivingLicenseState) ?? null,
      path: ["data", "Borrowers", "CoBorrower", "DLState"]
    },
    {
      value: coApplicantInfo?.email ?? null,
      path: ["data", "Borrowers", "CoBorrower", "Email"]
    },
    {
      value: coApplicantInfo?.homePhone ?? null,
      path: ["data", "Borrowers", "CoBorrower", "HomePhone"]
    },
    {
      value: coApplicantInfo?.mobilePhone ?? null,
      path: ["data", "Borrowers", "CoBorrower", "MobilePhone"]
    },
    {
      value: defaultAddress(coApplicantInfo),
      path: ["data", "Borrowers", "CoBorrower", "Addresses", "Address"]
    },
    {
      value:
        (coApplicantInfo?.additionalIncomes ?? [])?.reduce(
          (acc, additionalIncome) => acc + (additionalIncome.value || 0),
          0
        ) ?? null,
      path: ["data", "Borrowers", "CoBorrower", "OtherIncome", "Amount"]
    },
    {
      value:
        (coApplicantInfo?.additionalIncomes ?? [])
          ?.filter((x) => x.source)
          ?.map((x) => x.source)
          .join(", ") ?? null,
      path: ["data", "Borrowers", "CoBorrower", "OtherIncome", "Description"]
    },
    //Employment
    {
      value: defaultEmployment(coApplicantInfo),
      path: ["data", "Borrowers", "CoBorrower", "EmploymentInfo", "Employment"]
    },

    // COLLATERAL
    {
      value: resolveCollateralType(dealData?.info?.type),
      path: ["data", "Collateral", "Type"]
    },
    {
      value: (vehicle?.unitStatus ?? "").toUpperCase(),
      path: ["data", "Collateral", "Age"]
    },
    {
      value: vehicle?.make ?? null,
      path: ["data", "Collateral", "Make"]
    },
    {
      value: vehicle?.model ?? null,
      path: ["data", "Collateral", "Model"]
    },
    {
      value: resolveUnitClass(dealData?.info?.type, vehicle?.unitType),
      path: ["data", "Collateral", "RVClass"]
    },
    {
      value: vehicle?.VIN ?? null,
      path: ["data", "Collateral", "Serial"]
    },
    {
      value: vehicle?.year ?? null,
      path: ["data", "Collateral", "Year"]
    },
    {
      value: vehicle?.odometer ?? 0,
      path: ["data", "Collateral", "Mileage"]
    },
    {
      value: vehicle?.MSRP ?? 0,
      path: ["data", "Collateral", "MSRP"]
    },
    {
      value: price?.price ?? 0,
      path: ["data", "Collateral", "SellingPrice"]
    },
    {
      value: vehicle?.invoice ?? 0,
      path: ["data", "Collateral", "CostPrice"]
    },
    {
      value: vehicle?.hasTrailer ?? false,
      path: ["data", "Collateral", "hasTrailer"]
    },
    {
      value: unitStatus ? unitStatus?.toUpperCase() : null,
      path: ["data", "Collateral", "Trailer", "Age"]
    },
    {
      value: vehicle?.trailer?.make ?? null,
      path: ["data", "Collateral", "Trailer", "Make"]
    },
    {
      value: vehicle?.trailer?.model ?? null,
      path: ["data", "Collateral", "Trailer", "Model"]
    },
    {
      value: vehicle?.trailer?.year ?? null,
      path: ["data", "Collateral", "Trailer", "Year"]
    },
    {
      value: vehicle?.trailer?.serialNumber ?? null,
      path: ["data", "Collateral", "Trailer", "Serial"]
    },
    ...(vehicle?.boat?.engine?.make
      ? [
          {
            value: vehicle?.boat?.engine?.unitStatus
              ? vehicle?.boat?.engine?.unitStatus.toUpperCase()
              : null,
            path: ["data", "Collateral", "Motors", "Motor", 0, "Age"]
          },
          {
            value: vehicle?.boat?.engine?.engineNumber ?? null,
            path: ["data", "Collateral", "Motors", "Motor", 0, "Serial"]
          },
          {
            value: vehicle?.boat?.engine?.year ?? null,
            path: ["data", "Collateral", "Motors", "Motor", 0, "Year"]
          },
          {
            value: vehicle?.boat?.engine?.make ?? null,
            path: ["data", "Collateral", "Motors", "Motor", 0, "Make"]
          },
          {
            value: vehicle?.boat?.engine?.model ?? null,
            path: ["data", "Collateral", "Motors", "Motor", 0, "Model"]
          }
        ]
      : []),
    //Trade In

    {
      value: trade && trade.length > 0 ? true : false,
      path: ["data", "hasTradeIn"]
    },
    ...(trade && trade.length > 0
      ? trade?.reduce(
          (acc: { value: any; path: any[] }[], curr, index) => [
            ...acc,
            ...[
              {
                value: (index + 1).toString(),
                path: ["data", "TradeInInfo", "TradeIn", index, "NumOrder"]
              },
              {
                value: curr?.VIN ?? null,
                path: ["data", "TradeInInfo", "TradeIn", index, "Serial"]
              },

              {
                value: curr?.year ?? null,
                path: ["data", "TradeInInfo", "TradeIn", index, "Year"]
              },
              {
                value: curr?.make ?? null,
                path: ["data", "TradeInInfo", "TradeIn", index, "Make"]
              },
              {
                value: curr?.model ?? null,
                path: ["data", "TradeInInfo", "TradeIn", index, "Model"]
              },

              {
                value: curr?.value ?? null,
                path: ["data", "TradeInInfo", "TradeIn", index, "TradeInAllowance"]
              },
              {
                value: price?.payoff ?? null,
                path: ["data", "TradeInInfo", "TradeIn", index, "TradeInPayoff"]
              },
              {
                value: dealData?.payoffBank?.data?.info?.name ?? null,
                path: ["data", "TradeInInfo", "TradeIn", index, "LienHolder"]
              }
            ]
          ],
          []
        )
      : []),

    // Structure
    {
      value: price?.price ?? 0,
      path: ["data", "Structure", "SellingPrice"]
    },
    {
      value: price?.totalTrade ?? 0,
      path: ["data", "Structure", "TradeInAllowance"]
    },
    {
      value: price?.payoff ?? 0,
      path: ["data", "Structure", "TradeInPayoff"]
    },
    {
      value: price?.rebates ?? 0,
      path: ["data", "Structure", "Rebate"]
    },
    {
      value: price?.totalCash ?? 0,
      path: ["data", "Structure", "CashDown"]
    },
    {
      value: payment?.totalTaxes ?? 0,
      path: ["data", "Structure", "TotalTaxes"]
    },
    {
      value: payment?.totalFees ?? 0,
      path: ["data", "Structure", "TotalFees"]
    },
    {
      value: payment?.dealTotal ?? 0,
      path: ["data", "Structure", "TotalAmountFinanced"]
    },
    {
      value: payment?.numberOfPayments ?? 0,
      path: ["data", "Structure", "Term"]
    },
    {
      value: payment?.interestRate ?? 0,
      path: ["data", "Structure", "Rate"]
    },
    {
      value: payment?.daysToFirstPayment ?? 0,
      path: ["data", "Structure", "DaysToFirstPayment"]
    },
    {
      value: getFees(taxesAndFees),
      path: ["data", "Structure", "Fees", "Fee"]
    }
  ].reduce((acc, { path, value }) => setByPath(acc, path as any, value), initialState);
};

const isRvClass = (data: string | undefined): data is RVClassesValues => {
  return Object.values(RVClasses).some((el) => el === data);
};
const resolveUnitClass = (
  dealType: "Automotive" | "Marine" | "Recreational Vehicles" | "Power Sport" | undefined,
  unitType: string | undefined
): RVClassesValues | null => {
  switch (dealType) {
    case "Recreational Vehicles":
      const unityTypeTransformed = unitType?.toUpperCase().replaceAll(" ", "");
      return isRvClass(unityTypeTransformed) ? unityTypeTransformed : null;
    default:
      return null;
  }
};
const resolveCollateralType = (dealType: string | undefined): "MARINE" | "RV" | null => {
  switch (dealType) {
    case "Marine":
      return "MARINE";
    case "Recreational Vehicles":
      return "RV";
    default:
      return null;
  }
};
export default function AppOneModal({ stateAccess, isDefault }: Props) {
  const { enabled: editMode, edited: dealEdited } = useContext(formEditContext);
  const apponeStatus = useSelector(
    (state: RootState) => state.listApiStatusSlice["table"]
  )?.entities?.find((el) => el.id === process.env.REACT_APP_APPONE_API_ID);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [alertState, setAlertState] = useState(false);
  const appOneDeal = stateAccess.get<Deal>(["data", "appOneApplicationNumber"]);
  const appOneDealURL = stateAccess.get<Deal>(["data", "appOneApplicationURL"]);
  const dealId = stateAccess.get(["_id"]);
  const handleClickOpen = () => {
    if (appOneDealURL) {
      window.open(appOneDealURL);
    } else {
      if (dealEdited) {
        return setAlertState(true);
      }
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modalContainer = useContext(modalContainerContext);

  const isApponeOperational = apponeStatus?.status === "operational" || apponeStatus === undefined;
  return (
    <div>
      <AlertDialog
        closeFunc={setAlertState}
        open={alertState}
        content="Please save the deal before sending it to AppOne in order to not lose the changed data!"
      />
      <Grid container>
        <Grid item xs={isApponeOperational ? 12 : 10}>
          <Button
            variant="contained"
            style={{
              backgroundColor: appOneDeal ? "#50a538" : editMode ? "#254e70" : "#0000001f",
              color: editMode ? "#fff" : "black"
            }}
            id="appone-button"
            fullWidth
            disabled={!(appOneDeal || editMode)}
            onClick={handleClickOpen}
            startIcon={<MotorcycleIcon />}
            endIcon={isDefault && <StarRateIcon />}
            disableElevation
          >
            AppOne {appOneDeal}
          </Button>
        </Grid>
        {!isApponeOperational && (
          <Grid container item xs={2} justifyContent="center" alignItems="center">
            <Tooltip placement="top" title="AppOne service is unavailable!">
              <ErrorIcon color="secondary" />
            </Tooltip>
          </Grid>
        )}
      </Grid>
      {open
        ? ReactDOM.createPortal(
            <Dialog
              fullWidth
              maxWidth="lg"
              onClose={handleClose}
              open={open}
              style={{ minHeight: "50vh" }}
            >
              <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
                <Grid item xs={6} key="title">
                  <DialogTitle>
                    AppOne {appOneDeal}
                    <CloseDialogButton closeFunction={handleClose} />
                  </DialogTitle>
                </Grid>
              </Grid>
              <DialogContent>
                <AddEntity
                  buttonProps={{ text: "Send", startIcon: <Send /> }}
                  type="appone_import_application"
                  openInNewTab={false}
                  initialStateModifier={initializeAppOneData(stateAccess.get([]) as Deal)}
                  callback={() => {
                    setOpen(false);
                    dispatch(getEntity("deal", dealId));
                  }}
                />
              </DialogContent>
            </Dialog>,
            modalContainer
          )
        : null}
    </div>
  );
}

const getApplicantStatus = (
  propertyOwnership: PropertyOwnership | null
): "OWN" | "RENT" | "WITHRELATIVES" | "OTHER" | "" => {
  switch (propertyOwnership) {
    case "Owner":
      return "OWN";
    case "Rent":
      return "RENT";
    case "Family":
      return "WITHRELATIVES";
    case "Other":
      return "OTHER";
    case null:
      return "OWN";
    default:
      return "OTHER";
  }
};
const defaultEmployment = (applicantInfo: ApplicantInfo | null) => {
  const employmentStatus = getEmploymentStatus(applicantInfo?.employmentStatus ?? null);
  const workPhone = applicantInfo?.businessPhone ?? null;
  const employer = applicantInfo?.employer ?? null;
  const jobOccupation = applicantInfo?.jobOccupation ?? null;
  const monthsAtCurrentJob = applicantInfo?.monthsAtCurrentJob?.toString() ?? null;
  const annualIncome = applicantInfo?.annualIncome ?? null;
  const yearsAtCurrentJob = applicantInfo?.yearsAtCurrentJob?.toString() ?? null;
  const previousEmployer = applicantInfo?.previousEmployer ?? null;
  const monthsAtPreviousJob = applicantInfo?.monthsAtPreviousJob?.toString() ?? null;
  const yearsAtPreviousJob = applicantInfo?.yearsAtPreviousJob?.toString() ?? null;

  return [
    {
      IsCurrent: "true",
      Status: employmentStatus,
      Occupation: jobOccupation,
      EmployerName: employer,
      GrossSalary: annualIncome,
      GrossSalaryType: "ANNUALLY",
      HowLongYears: yearsAtCurrentJob,
      HowLongMonths: monthsAtCurrentJob,
      WorkPhone: workPhone
    },
    {
      IsCurrent: "false",
      Status: "RETIRED",
      Occupation: "RETIRED",
      EmployerName: previousEmployer,
      HowLongYears: yearsAtPreviousJob,
      HowLongMonths: monthsAtPreviousJob
    }
  ];
};
const defaultAddress = (applicantInfo: ApplicantInfo | null) => {
  const currentAddress = applicantInfo?.currentAddress ?? null;
  const currentAddressNumber = applicantInfo?.currentAddressNumber ?? null;
  const zipCode = applicantInfo?.currentZipCode ?? null;
  const city = applicantInfo?.currentCity ?? null;
  const statePrefix = getPrefixByState(applicantInfo?.currentState) ?? null;
  const county = applicantInfo?.currentCounty ?? null;
  const yearsAtCurrentAddress = applicantInfo?.yearsAtCurrentAddress ?? null;
  const monthsAtCurrentAddress = applicantInfo?.monthsAtCurrentAddress ?? 0;
  const propertyOwnership = applicantInfo?.propertyOwnership ?? null;
  const monthlyPayment =
    propertyOwnership === "Owner"
      ? applicantInfo?.mortgagePerMonth
      : propertyOwnership === "Rent"
      ? applicantInfo?.rentPerMonth
      : 0;
  const status = getApplicantStatus(propertyOwnership);
  const previousAddress = applicantInfo?.previousAddress ?? null;
  const previousAddressNumber = applicantInfo?.previousAddressNumber ?? null;
  const previousZipCode = applicantInfo?.previousZipCode ?? null;
  const previousCity = applicantInfo?.previousCity ?? null;
  const previousStatePrefix = getPrefixByState(applicantInfo?.previousState) ?? null;
  const previousCounty = applicantInfo?.previousCounty ?? null;
  const yearsAtPreviousAddress = applicantInfo?.yearsAtPreviousAddress ?? null;
  const monthsAtPreviousAddress = applicantInfo?.monthsAtPreviousAddress ?? 0;
  const previousPropertyOwnership = applicantInfo?.previousPropertyOwnership ?? null;
  const previousMonthlyPayment =
    previousPropertyOwnership === "Owner"
      ? applicantInfo?.previousMortgagePerMonth
      : previousPropertyOwnership === "Rent"
      ? applicantInfo?.previousRentPerMonth
      : 0;
  const previousStatus = getApplicantStatus(previousPropertyOwnership);
  return [
    {
      IsCurrent: "true",
      IsMailing: "true",
      StreetNo: currentAddressNumber,
      StreetName: currentAddress,
      ZipCode: zipCode,
      City: city,
      State: statePrefix,
      County: county,
      HowLongYears: yearsAtCurrentAddress?.toString(),
      HowLongMonths: monthsAtCurrentAddress?.toString(),
      MonthlyPayment: monthlyPayment,
      Status: status
    },
    {
      IsCurrent: "false",
      IsMailing: "true",
      StreetNo: currentAddressNumber,
      StreetName: currentAddress,
      ZipCode: zipCode,
      City: city,
      State: statePrefix,
      County: county,
      HowLongYears: yearsAtCurrentAddress?.toString(),
      HowLongMonths: monthsAtCurrentAddress?.toString(),
      MonthlyPayment: monthlyPayment,
      Status: status
    },
    {
      IsCurrent: "false",
      IsMailing: "false",
      StreetNo: previousAddressNumber,
      StreetName: previousAddress,
      ZipCode: previousZipCode,
      City: previousCity,
      State: previousStatePrefix,
      County: previousCounty,
      HowLongYears: yearsAtPreviousAddress?.toString(),
      HowLongMonths: monthsAtPreviousAddress?.toString(),
      MonthlyPayment: previousMonthlyPayment,
      Status: previousStatus
    }
  ];
};

const getEmploymentStatus = (
  status: EmploymentStatus | null | undefined
): "EMPLOYED" | "SELFEMPLOYED" | "RETIRED" | "" => {
  switch (status) {
    case "Employee":
      return "EMPLOYED";
    case "Business owner":
      return "SELFEMPLOYED";
    case "Retired":
      return "RETIRED";
    case null:
    case undefined:
      return "EMPLOYED";
    default:
      return "EMPLOYED";
  }
};

const getFees = (taxesAndFees: TaxesAndFees | null) => {
  const documentRegistrationFees = taxesAndFees?.documentRegistrationFees ?? 0;
  const titleTransfer = taxesAndFees?.titleTransfer ?? 0;

  return [
    { Code: "title", Name: "Title Fee", Amount: titleTransfer, IsTaxed: false },
    {
      Code: "license",
      Name: "License Fee",
      Amount: 0,
      IsTaxed: false
    },
    {
      Code: "lien",
      Name: "Lien Fee",
      Amount: 0,
      IsTaxed: false
    },
    {
      Code: "doc",
      Name: "Documentation Fee",
      Amount: documentRegistrationFees,
      IsTaxed: false
    },
    {
      Code: "ucc",
      Name: "UCC Filing Fee",
      Amount: 0,
      IsTaxed: false
    },
    {
      Code: "service",
      Name: "Service Fee",
      Amount: 0,
      IsTaxed: false
    }
  ];
};
