import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { MutationError } from "Chat/types";
import { auth } from "../../firebase/firebase";
import {
  NotifyCustomerCareNotesRequest,
  NotifyDealerNotesRequest,
  NotifyDealershipTicketNotesRequest,
  NotifyTitleIssueNotesRequest
} from "./types";

export const notifyDealerNotes = (): ((request: NotifyDealerNotesRequest) => Promise<string>) => {
  return async (request) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/notify_dealer_notes/`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return response.data.result;
  };
};
export const notifyDealerStipulations = (): ((request: { dealId: string }) => Promise<string>) => {
  return async (request) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/notify_dealer_stipulations/`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return response.data.result;
  };
};
export const notifyCustomerCareNotes = (): ((
  request: NotifyCustomerCareNotesRequest
) => Promise<string>) => {
  return async (request) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/notify_customer_care_notes/`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return response.data.result;
  };
};
export const notifyTitleIssueNotes = (): ((
  request: NotifyTitleIssueNotesRequest
) => Promise<string>) => {
  return async (request) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/notify_title_issue_notes/`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return response.data.result;
  };
};
export const notifyDealershipTicketNotes = (): ((
  request: NotifyDealershipTicketNotesRequest
) => Promise<string>) => {
  return async (request) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/notify_dealership_ticket_notes/`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    return response.data.result;
  };
};
export const useNotifyDealershipTicketNotes = () => {
  const mutation = useMutation<string, MutationError, NotifyDealershipTicketNotesRequest>(
    notifyDealershipTicketNotes(),
    {
      onSuccess: () => {
        return;
      }
    }
  );
  return {
    notifyDealershipTicketNotes: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};
export const useNotifyTitleIssueNotes = () => {
  const mutation = useMutation<string, MutationError, NotifyTitleIssueNotesRequest>(
    notifyTitleIssueNotes(),
    {
      onSuccess: () => {
        return;
      }
    }
  );
  return {
    notifyTitleIssueNotes: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};
export const useNotifyDealerNotes = () => {
  const mutation = useMutation<string, MutationError, NotifyDealerNotesRequest>(
    notifyDealerNotes(),
    {
      onSuccess: () => {
        return;
      }
    }
  );
  return {
    notifyDealerNotes: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};
export const useNotifyDealerStipulations = () => {
  const mutation = useMutation<string, MutationError, { dealId: string }>(
    notifyDealerStipulations(),
    {
      onSuccess: () => {
        return;
      }
    }
  );
  return {
    notifyDealerStipulations: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};
export const useNotifyCustomerCareNotes = () => {
  const mutation = useMutation<string, MutationError, NotifyCustomerCareNotesRequest>(
    notifyCustomerCareNotes(),
    {
      onSuccess: () => {
        return;
      }
    }
  );
  return {
    notifyCustomerCareNotes: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error
  };
};
export default useNotifyDealerNotes;
