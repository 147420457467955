import React from "react";
import { APIProvider, InfoWindow, Map, useMapsLibrary } from "@vis.gl/react-google-maps";
import states from "./states";
import { Polygon } from "./Polygon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { uuid } from "uuidv4";
import {
  getLenderDecision,
  removeLenderDecisionsList
} from "components/LenderDecisions/listLenderDecisionsSlice";
import { getLenderList, removeLenderList } from "components/Lenders/listLenderSlice";
import { Lender, OperatesInStateStatus } from "components/Lenders/types";
import { LenderDescision } from "components/LenderDecisions/types";
import { Button, Grid, List, ListItem, Paper, TextField, Typography } from "@material-ui/core";
import counties from "./counties";
import { CoordinatesObject } from "./types";
import { GoVerified, GoUnverified } from "react-icons/go";
import { useStickyState } from "index";
import { User } from "components/Users/types";
import { useSnackbar } from "notistack";
import { TabContext } from "components/Layout/LayoutWrapper";
import Spinner from "components/Loader/Spinner";
import { IoMdSquare } from "react-icons/io";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CollateralTypes, collateralTypes as defaultCollateralTypes } from "components/Deals/types";
import { Info } from "@material-ui/icons";
import { HintTooltip } from "components/common/HintTooltip";
type CoordinatesData = {
  coordinates: CoordinatesObject[];
  name: string;
  lenders: (Lender & { verified: boolean })[];
  strokeColor: string;
  lenderDecisions: LenderDescision[];
  counties: {
    strokeColor: string;
    name: string;
    coordinates: CoordinatesObject[];
    lenders: (Lender & { verified: boolean })[];
    lenderDecisions: LenderDescision[];
  }[];
};
const addCountiesToStateByFPCode = (stateFpCode: string) => {
  return counties.features
    .filter((feature) => feature.properties.STATEFP === stateFpCode)
    .map((feature) => {
      if (feature.geometry.type === "Polygon") {
        return {
          coordinates: feature.geometry.coordinates[0].map(([lng, lat]) => {
            return { lng, lat } as { lng: number; lat: number };
          }),
          name: feature.properties.NAME,
          lenders: [],
          strokeColor: "#7f857e",
          lenderDecisions: []
        };
      }
      return {
        coordinates: feature.geometry.coordinates.map((polygonCoordinates) => {
          // @ts-ignore
          return polygonCoordinates[0].map(([lng, lat]) => {
            return { lng, lat } as { lng: number; lat: number };
          });
        }),
        name: feature.properties.NAME,
        lenders: [],
        strokeColor: "#7f857e",
        lenderDecisions: []
      };
    });
};
const initializeCoordinateData = (): CoordinatesData[] => {
  return states.features.map((state) => {
    if (state.geometry.type === "Polygon") {
      return {
        coordinates: state.geometry.coordinates[0].map(([lng, lat]) => {
          return { lng, lat } as { lng: number; lat: number };
        }),
        name: state.properties.name,
        lenders: [],
        lenderDecisions: [],
        strokeColor: "#7f857e",
        counties: addCountiesToStateByFPCode(state.id)
      };
    }
    return {
      coordinates: state.geometry.coordinates.map((polygonCoordinates) => {
        // @ts-ignore
        return polygonCoordinates[0].map(([lng, lat]) => {
          return { lng, lat } as { lng: number; lat: number };
        });
      }),
      name: state.properties.name,
      lenders: [],
      strokeColor: "#7f857e",
      lenderDecisions: [],
      counties: addCountiesToStateByFPCode(state.id)
    };
  });
};

const getLenderDecisionsByCollateralType = (lender: Lender, decisions: LenderDescision[]) => {
  return decisions
    .filter(
      (decision) =>
        decision.data.info?.lender === lender.data.info.name ||
        lender.data.info.aliases
          ?.map((alias) => alias.alias)
          ?.includes(decision.data.info?.lender as string)
    )
    .reduce((acc, decision) => {
      const collateralType = decision.data.dealType as CollateralTypes;
      return {
        ...acc,
        [collateralType]: (acc?.[collateralType] || 0) + 1
      };
    }, {} as Record<CollateralTypes, number>);
};
const getStrokeColorByLendersAndDecisions = (
  lenders: (Lender & { verified: boolean })[],
  decisions: LenderDescision[]
) => {
  const nonDeclinedDecisions = decisions.filter(
    (decision) => decision.data.info?.decision.toLowerCase() !== "declined"
  );
  if (lenders?.length && !nonDeclinedDecisions?.length) {
    return "#f5dd02";
  }
  if (
    (!lenders?.length && nonDeclinedDecisions?.length) ||
    lenders
      ?.filter((lender) => !lender.verified)
      ?.some((lender) =>
        nonDeclinedDecisions.some((decision) =>
          [
            lender?.data?.info?.name,
            ...((lender.data.info?.aliases?.map((alias) => alias.alias) ?? []) as string[])
          ].includes(decision.data?.info?.lender as string)
        )
      )
  ) {
    return "#e60202";
  }
  if (lenders?.filter((lender) => lender.verified)?.length && nonDeclinedDecisions?.length) {
    return "#26bf04";
  }
  return "#7f857e";
};
function getCenterOfCoordinates(coordinates: CoordinatesObject[]) {
  let allCoordinates: {
    lat: number;
    lng: number;
  }[] = [];

  // Check if it's a multipolygon
  if (Array.isArray(coordinates[0])) {
    coordinates.forEach((polygon) => {
      allCoordinates = allCoordinates.concat(polygon);
    });
  } else {
    allCoordinates = coordinates as {
      lat: number;
      lng: number;
    }[];
  }

  const totalPoints = allCoordinates.length;

  let sumLat = 0;
  let sumLng = 0;

  allCoordinates.forEach((coord) => {
    sumLat += coord.lat;
    sumLng += coord.lng;
  });

  const centerLat = sumLat / totalPoints;
  const centerLng = sumLng / totalPoints;

  return { lat: centerLat, lng: centerLng };
}
const addLendersAndDecisionsToCounties = (
  state: string,
  stateLenders: (Lender & { verified: boolean })[],
  stateDecisions: LenderDescision[],
  counties: {
    name: string;
    coordinates: CoordinatesObject[];
    lenders: (Lender & { verified: boolean })[];
    lenderDecisions: LenderDescision[];
  }[]
) => {
  return counties.map((county) => {
    const filteredStateDecisionsByCounty = stateDecisions?.filter(
      (decision) => decision?.data?.county === county.name
    );
    const countyLenders: (Lender & { verified: boolean })[] = [];
    stateLenders.forEach((lender) => {
      const operatingLenderStates = lender?.data?.info?.operatingStates?.states;
      const lenderOperatesInCountyOrState = operatingLenderStates?.some((operatingLenderState) => {
        const operatesInCounty =
          operatingLenderState?.operatesInSomeCounties &&
          operatingLenderState?.operatingCounties
            ?.flatMap?.((operatingCounty) => operatingCounty?.counties)
            ?.includes(county.name) &&
          operatingLenderState.status !== OperatesInStateStatus.INACTIVE;

        const operatesInWholeState =
          operatingLenderState.state === state &&
          !operatingLenderState.operatesInSomeCounties &&
          operatingLenderState.status !== OperatesInStateStatus.INACTIVE;

        return operatesInCounty || operatesInWholeState;
      });
      const decisionButLenderDoesNotOperate = filteredStateDecisionsByCounty?.some((decision) =>
        [
          lender.data.info.name,
          ...(lender?.data?.info?.aliases?.map((alias) => alias.alias) ?? [])
        ].includes(decision.data?.info?.lender as string)
      );
      if (lenderOperatesInCountyOrState || decisionButLenderDoesNotOperate) {
        countyLenders.push(lender);
      }
    });
    return {
      ...county,
      lenderDecisions: filteredStateDecisionsByCounty,
      lenders: countyLenders,
      strokeColor: getStrokeColorByLendersAndDecisions(
        countyLenders,
        filteredStateDecisionsByCounty
      )
    };
  });
};
const LenderCoverageMapHOC = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state: RootState) => state?.authSlice);
  const [requestId] = React.useState(uuid());
  const lenderDecisionsList = useSelector(
    (state: RootState) => state.listLenderDecisionSlice[requestId]
  );
  const [requestState, setRequestState] = useStickyState(
    { allLenders: true, allCollateralTypes: true },
    "lender_coverage_map_requestState"
  );
  const [lenders, setLenders] = useStickyState<Lender[]>([], "lender_coverage_map_lenders");
  const [collateralTypes, setCollateralTypes] = useStickyState<string[]>(
    [],
    "lender_coverage_map_collateralTypes"
  );
  const [coordinatesData, setCoordinatesData] = React.useState<CoordinatesData[]>([]);
  const lendersList = useSelector((state: RootState) => state.listLenderSlice[requestId]);
  React.useEffect(() => {
    if (lenderDecisionsList?.status === "success" && lendersList?.status === "success") {
      const initialCoordinatesData = initializeCoordinateData();
      const filteredLenderEntities = lendersList?.entities?.filter((lender) =>
        requestState?.allLenders ? true : lenders.some((l) => l._id === lender._id)
      );
      setCoordinatesData(
        initialCoordinatesData.map((coordinateData) => {
          const stateDecisions = lenderDecisionsList?.entities?.filter(
            (lenderDecision) =>
              lenderDecision?.data?.state === coordinateData.name &&
              filteredLenderEntities?.find(
                (lender) =>
                  lender.data.info.name === lenderDecision?.data?.info?.lender ||
                  lender.data.info.aliases
                    ?.map((alias) => alias.alias)
                    ?.includes(lenderDecision?.data?.info?.lender as string)
              )?.data?.info?.operatingStates?.status !== OperatesInStateStatus.INACTIVE
          ) as LenderDescision[];
          const stateLenders: (Lender & { verified: boolean })[] = [];
          filteredLenderEntities?.forEach((lender) => {
            const decisionButLenderDoesNotOperateInState = stateDecisions?.some(
              (decision) =>
                decision?.data?.info?.lender === lender.data.info.name ||
                lender?.data?.info?.aliases
                  ?.map((alias) => alias.alias)
                  ?.includes(decision?.data?.info?.lender as string)
            );
            const lenderOperatesInState =
              lender?.data?.info?.operatingStates?.status !== OperatesInStateStatus.INACTIVE &&
              (lender?.data?.info?.operatingStates?.states ?? [])?.some(
                (state) => state.state === coordinateData.name
              );
            if (lenderOperatesInState) {
              stateLenders.push({ ...lender, verified: true });
            } else if (decisionButLenderDoesNotOperateInState) {
              stateLenders.push({ ...lender, verified: false });
            }
          });
          return {
            ...coordinateData,
            lenders: stateLenders,
            lenderDecisions: stateDecisions,
            strokeColor: getStrokeColorByLendersAndDecisions(stateLenders, stateDecisions),
            counties: addLendersAndDecisionsToCounties(
              coordinateData.name,
              stateLenders,
              stateDecisions,
              coordinateData.counties
            )
          };
        })
      );
    }
  }, [lenderDecisionsList?.entities, lendersList?.entities]);
  React.useEffect(() => {
    dispatch(
      getLenderDecision(requestId, {
        query: {
          "data.info.lender": { $exists: true },
          ...(!requestState?.allLenders
            ? {
                "data.info.lender": {
                  $in: Array.from(
                    new Set(
                      lenders
                        .map((lender) => [
                          lender.data.info.name,
                          ...(lender.data.info.aliases?.map((alias) => alias.alias) ?? [])
                        ])
                        .flat()
                    )
                  )
                }
              }
            : {}),
          ...(!requestState?.allCollateralTypes
            ? {
                "data.dealType": {
                  $in: collateralTypes
                }
              }
            : {})
        },
        options: {
          projection: {
            _id: 1,
            "data.dealId": 1,
            "data.info.decision": 1,
            "data.info.lender": 1,
            "data.dealType": 1,
            "data.state": 1,
            "data.county": 1
          },
          pagination: false
        }
      })
    );
    return () => {
      dispatch(removeLenderDecisionsList(requestId));
      return;
    };
  }, [requestState]);

  React.useEffect(() => {
    dispatch(
      getLenderList(requestId, {
        options: {
          projection: {
            _id: 1,
            "data.info.operatingStates": 1,
            "data.info.name": 1,
            "data.info.aliases": 1,
            "data.info.state": 1,
            "data.info.county": 1
          },
          pagination: false
        }
      })
    );
    return () => {
      dispatch(removeLenderList(requestId));
      return;
    };
  }, [requestState]);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography style={{ fontSize: "19px", fontWeight: "bold" }}>
          Lender coverage map
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          style={{ zIndex: 6 }}
          value={
            requestState.allLenders ? ((["All lenders"] as unknown) as Lender[]) : lenders || null
          }
          getOptionLabel={(lender) => {
            if (((lender as unknown) as string) === "All lenders") {
              return (lender as unknown) as string;
            }
            return lender.data.info.name ?? "";
          }}
          multiple
          getOptionSelected={(x, y) => x?._id === y?._id && x === y}
          options={[("All lenders" as unknown) as Lender, ...(lendersList?.entities ?? [])]}
          onChange={(event, newValue) => {
            if (newValue?.length === 0) {
              setRequestState((prev) => ({ ...prev, allLenders: true }));
              setLenders((["All lenders"] as unknown) as Lender[]);
            } else {
              setLenders(newValue.filter((x) => x !== (("All lenders" as unknown) as Lender)));
              setRequestState((prev) => ({ ...prev, allLenders: false }));
            }
          }}
          loading={!Array.isArray(lendersList?.entities)}
          openOnFocus
          id="lender-coverage-map-lender-select"
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              InputProps={{ ...params.InputProps }}
              label={"Lender"}
              variant="filled"
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          style={{ zIndex: 6 }}
          value={
            requestState.allCollateralTypes ? ["All collateral types"] : collateralTypes || null
          }
          getOptionLabel={(collateralType) => collateralType}
          multiple
          getOptionSelected={(x, y) => x === y}
          options={["All collateral types", ...defaultCollateralTypes]}
          onChange={(event, newValue) => {
            if (newValue?.length === 0) {
              setCollateralTypes(["All collateral types"]);
              setRequestState((prev) => ({ ...prev, allCollateralTypes: true }));
            } else {
              const filteredValue = newValue.filter((x) => x !== "All collateral types");
              setCollateralTypes(filteredValue);
              setRequestState((prev) => ({ ...prev, allCollateralTypes: false }));
            }
          }}
          openOnFocus
          id="collateral-types-select"
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              InputProps={{ ...params.InputProps }}
              label={"Collateral type"}
              variant="filled"
              size="small"
            />
          )}
        />
      </Grid>
      {coordinatesData?.length &&
      !["loading", "waiting"].includes(lendersList?.status as string) &&
      !["loading", "waiting"].includes(lenderDecisionsList?.status as string) ? (
        <React.Fragment>
          <Grid item xs={12}>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}>
              <LenderCoverageMap
                coordinatesData={coordinatesData}
                currentUser={currentUser.user?.databaseData}
              />
            </APIProvider>
          </Grid>
          <Grid item xs={12}>
            <Paper style={{ padding: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <IoMdSquare color="#26bf04" size={"20px"} />
                  <Typography>Zone with active lenders and decisions</Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <IoMdSquare color="#f5dd02" size={"20px"} />
                  <Typography>Zone with active lenders but no decisions</Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <IoMdSquare color="#e60202" size={"20px"} />
                  <Typography>
                    Zone with decisions but no active lenders (or with active lenders which do not
                    operate in the zone)
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <IoMdSquare color="#7f857e" size={"20px"} />
                  <Typography>Zone with no active lenders and decisions </Typography>
                </div>
              </div>
            </Paper>
          </Grid>
        </React.Fragment>
      ) : (
        <Spinner text="Loading..." style={{ position: "relative", zIndex: 5 }} />
      )}
    </Grid>
  );
};
type LenderCoverageMapProps = {
  coordinatesData: CoordinatesData[];
  currentUser: User | undefined;
};
const LenderCoverageMap = ({
  currentUser,
  coordinatesData
}: LenderCoverageMapProps): JSX.Element => {
  const createOrFocusTab = React.useContext(TabContext);

  const { enqueueSnackbar } = useSnackbar();

  const [countiesData, setCountiesData] = React.useState<
    | {
        name: string;
        strokeColor: string;
        coordinates: CoordinatesObject[];
        lenders: (Lender & { verified: boolean })[];
        lenderDecisions: LenderDescision[];
      }[]
    | null
  >(null);
  const [center, setCenter] = React.useState<{ lat: number; lng: number } | undefined>({
    lat: 44.09003743593453,
    lng: -102.9859875124825
  });
  const [zoom, setZoom] = React.useState<number | undefined>(3);
  const [coverageReportStates, setCoverageReportStates] = useStickyState<string[]>(
    ["Minnesota"],
    "lender_credit_statistics_states"
  );
  const maps = useMapsLibrary("maps");
  const [statePopupInfo, setStatePopupInfo] = React.useState<{
    lng: number;
    lat: number;
    name: string;
    counties: {
      strokeColor: string;
      lenders: (Lender & { verified: boolean })[];
      lenderDecisions: LenderDescision[];
      name: string;
      coordinates: CoordinatesObject[];
    }[];
    center: { lng: number; lat: number };
    lenders: (Lender & { verified: boolean })[];
    lenderDecisions: LenderDescision[];
  } | null>(null);
  const [countyPopupInfo, setCountyPopupInfo] = React.useState<{
    lng: number;
    lat: number;
    name: string;
    lenders: (Lender & { verified: boolean })[];
    lenderDecisions: LenderDescision[];
  } | null>(null);

  const handleViewCounties = (
    counties: {
      name: string;
      coordinates: CoordinatesObject[];
      strokeColor: string;
      lenders: (Lender & { verified: boolean })[];
      lenderDecisions: LenderDescision[];
    }[],
    center: { lat: number; lng: number }
  ) => {
    setStatePopupInfo(null);
    setCountiesData(counties);
    setCenter(center);
    setZoom(7);
  };
  React.useEffect(() => {
    if (coverageReportStates?.length === 1 && coverageReportStates[0] === statePopupInfo?.name) {
      if (currentUser?.data?.info?.dashboardTypes?.coverageStatistics) {
        setStatePopupInfo(null);
        createOrFocusTab({
          label: "Lender Coverage Statistics",
          index: "lender_coverage_statistics",
          isForSidebar: true,
          isForQuickAccess: true,
          isPersistent: true
        });
      } else {
        enqueueSnackbar(
          `To open the report, you should enable the dashboard for your user first.`,
          {
            variant: "error"
          }
        );
      }
    }
  }, [coverageReportStates]);
  const handleOpenLenderCoverageReport = (state: string) => {
    setCoverageReportStates([state]);
  };

  if (!maps) {
    return <Spinner text="Loading..." style={{ position: "relative", zIndex: 5 }} />;
  }
  return (
    <Map
      id="map"
      style={{ height: 800 }}
      mapId={"test"}
      zoom={zoom}
      center={center}
      onZoomChanged={(e) => {
        setZoom(undefined);
        if (e.detail.zoom <= 6) {
          setCountiesData(null);
          setCountyPopupInfo(null);
        }
      }}
      onCenterChanged={() => {
        setCenter(undefined);
      }}
      mapTypeId={"roadmap"}
    >
      {countiesData === null &&
        coordinatesData.map(
          ({ coordinates, counties, name, strokeColor, lenderDecisions, lenders }, index) => (
            <Polygon
              key={index}
              fillColor={strokeColor}
              fillOpacity={0.5}
              strokeOpacity={0.15}
              paths={coordinates}
              onClick={(e) => {
                const lat = e.latLng?.lat();
                const lng = e.latLng?.lng();
                if (typeof lat === "number" && typeof lng === "number") {
                  setStatePopupInfo({
                    lat,
                    lng,
                    name,
                    counties,
                    lenders,
                    lenderDecisions,
                    center: getCenterOfCoordinates(coordinates)
                  });
                }
              }}
            />
          )
        )}
      {(countiesData ?? []).map(
        ({ coordinates, lenders, strokeColor, lenderDecisions, name }, index) => {
          return (
            <Polygon
              key={index}
              fillColor={strokeColor}
              fillOpacity={0.5}
              strokeOpacity={0.15}
              zIndex={999}
              paths={coordinates}
              onClick={(e) => {
                const lat = e.latLng?.lat();
                const lng = e.latLng?.lng();
                if (typeof lat === "number" && typeof lng === "number") {
                  setCountyPopupInfo({ lat, lng, name, lenders, lenderDecisions });
                }
              }}
            />
          );
        }
      )}
      {statePopupInfo && (
        <InfoWindow
          position={{ lat: statePopupInfo.lat, lng: statePopupInfo.lng }}
          maxWidth={600}
          onCloseClick={() => setStatePopupInfo(null)}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography>{statePopupInfo.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <List>
                {statePopupInfo.lenders?.length ? (
                  statePopupInfo.lenders.map((lender, index) => {
                    const lenderDecisionsByCollateralType = getLenderDecisionsByCollateralType(
                      lender,
                      statePopupInfo.lenderDecisions
                    );
                    const totalCount = Object.values(lenderDecisionsByCollateralType).reduce(
                      (acc, count) => acc + count,
                      0
                    );
                    return (
                      <ListItem key={index}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <Typography>{lender.data.info.name}</Typography>
                          {lender.verified ? (
                            <GoVerified color="#26bf04" size={20} />
                          ) : (
                            <GoUnverified color="#e60202" size={20} />
                          )}
                          <Typography> - {totalCount}</Typography>
                          {totalCount > 0 && (
                            <HintTooltip
                              title={
                                <List>
                                  {Object.entries(lenderDecisionsByCollateralType).map(
                                    ([collateralType, count], i) => (
                                      <ListItem key={i}>
                                        <Typography style={{ fontSize: "12px" }}>
                                          {collateralType} - {count}
                                        </Typography>
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              }
                            >
                              <Info style={{ color: "rgb(129, 126, 126)" }} />
                            </HintTooltip>
                          )}
                        </div>
                      </ListItem>
                    );
                  })
                ) : (
                  <ListItem>
                    <Typography>No data</Typography>
                  </ListItem>
                )}
              </List>
            </Grid>
            {statePopupInfo.lenders?.length ? (
              <Grid item xs={12} spacing={1} container>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) =>
                      handleViewCounties(statePopupInfo.counties, statePopupInfo.center)
                    }
                    fullWidth
                  >
                    View counties
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      handleOpenLenderCoverageReport(statePopupInfo.name);
                    }}
                    fullWidth
                  >
                    Show report for state
                  </Button>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </InfoWindow>
      )}
      {countyPopupInfo && (
        <InfoWindow
          position={{ lat: countyPopupInfo.lat, lng: countyPopupInfo.lng }}
          maxWidth={600}
          onCloseClick={() => setCountyPopupInfo(null)}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography>{countyPopupInfo.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <List>
                {countyPopupInfo?.lenders?.length ? (
                  countyPopupInfo.lenders.map((lender, index) => {
                    const lenderDecisionsByCollateralType = getLenderDecisionsByCollateralType(
                      lender,
                      countyPopupInfo.lenderDecisions
                    );
                    const totalCount = Object.values(lenderDecisionsByCollateralType).reduce(
                      (acc, count) => acc + count,
                      0
                    );
                    return (
                      <ListItem key={index}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <Typography>{lender.data.info.name}</Typography>
                          {lender.verified ? (
                            <GoVerified color="#26bf04" size={20} />
                          ) : (
                            <GoUnverified color="#e60202" size={20} />
                          )}
                          <Typography> - {totalCount}</Typography>
                          {totalCount > 0 && (
                            <HintTooltip
                              title={
                                <List>
                                  {Object.entries(lenderDecisionsByCollateralType).map(
                                    ([collateralType, count], i) => (
                                      <ListItem key={i}>
                                        <Typography style={{ fontSize: "12px" }}>
                                          {collateralType} - {count}
                                        </Typography>
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              }
                            >
                              <Info style={{ color: "rgb(129, 126, 126)" }} />
                            </HintTooltip>
                          )}
                        </div>
                      </ListItem>
                    );
                  })
                ) : (
                  <ListItem>
                    <Typography>No data</Typography>
                  </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
        </InfoWindow>
      )}
    </Map>
  );
};
export default LenderCoverageMapHOC;
