import { AdditionalRequiredDocument } from "components/AdditionalRequiredDocuments/types";
import { Deal as AppOneDeal } from "components/AppOne/Deal/types";
import { ASCInsurance } from "components/ASCWarranty/types";
import { DealerNote } from "components/DealerNotes/types";
import { Dealership, RelationTypes } from "components/Dealerships/types";
import { DocumentTemplate } from "components/DocumentTemplates/types";
import { File } from "components/Files/types";
import { Lender, MarkupType } from "components/Lenders/types";
import { ManagerProgram } from "components/ManagerPrograms/types";
import { Note } from "components/Notes/types";
import { PayoffBank } from "components/PayoffBanks/types";
import { PrintedDocument } from "components/PrintedDocuments/types";
import { ProtectiveInsurance } from "components/Protective/types";
import { Sequence } from "components/Sequence/types";
import { State } from "components/States/types";
import { Stipulation } from "components/Stipulations/types";
import { User } from "components/Users/types";
import { VehicleInsuranceCompany } from "components/VehicleInsuranceCompany/types";
import { Collateral } from "types/collateral";
import { CreditPrequalify } from "types/credit_prequalify";
import { LenderPrediction, Prediction } from "types/prediction";
import { AddRequest, CrudEntity } from "utils/types";
import { Applicant } from "../Applicants/types";
import { CreditBureau, CreditCheckTypes } from "../CreditScore/types";
import { DynamicSurcharge, Product } from "../ExpressApi/Deal/types";
import { PackType } from "./Documents/types";
import { OneSpanSigning } from "./OneSpan/types";

export type Deal = CrudEntity & {
  data: DealData;
};

export interface DealToEdit {
  _id: string;
  data: DealData;
  dataHash?: string;
  approved?: boolean | null;
  approvedAt?: string;
  updatedAt?: string;
  approvedByUserId?: string;
  availableToRolesIds?: string[] | null;
}
export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
export interface PartialDealToEdit {
  _id: string;
  data: RecursivePartial<DealData>;
  approved?: boolean | null;
  approvedAt?: string;
  approvedByUserId?: string;
  availableToRolesIds?: string[] | null;
}

export interface DealResponse {
  message: {
    data: DealData;
    _id: string;
    createdAt: string;
  };
}

export type AddDealRequestSend = { data: DealDataToSend; availableToRolesIds?: string[] | null };

export type DealToEditSend = {
  _id: string;
  data: DealDataToSend;
  approved?: boolean | null;
  approvedAt?: string;
  updatedAt?: string;
  approvedByUserId?: string;
  dataHash?: string;
  availableToRolesIds?: string[] | null;
};
export interface PartialDealToEditSend {
  _id: string;
  data: RecursivePartial<DealDataToSend>;
  availableToRolesIds?: string[] | null;
  type: "partial";
}
type DealertrackDealNumbers = { dealertrackNumber: string; dateSubmitted: string }[] | null;
export interface DealDataToSend {
  info: Omit<Info, "refNumber">;
  notes?: Note[];
  source?: DealSource;
  stateTaxId: string | null;
  applicantId: string | null;
  payoffBankId: string | null;
  coApplicantId: string | null;
  netsuiteEstimateId?: string | null;
  netsuiteSalesOrderId?: string | null;
  netsuiteInvoiceId?: string | null;
  netsuiteVendorBillIds?: NetsuiteVendorBillId[];
  lenderId: string | null;
  userId: string | null;
  dealershipId: string | null;
  vehicleInsuranceCompanyId: string | null;
  appOneData: AppOneDeal | null;
  appOneApplicationNumber: string | null;
  appOneApplicationURL: string | null;
  dealertrackDealNumbers: DealertrackDealNumbers;
  creditSmartsId?: string;
  creditSmartsDate?: string;
  availableToRolesIds?: string[] | null;
  netsuiteStatus: NetsuiteStatuses;
  oneSpanSignings?: OneSpanSigning[];
}
interface NetsuiteStatus {
  status: "success" | "loading" | "error" | "none";
  message: string;
}
interface NetsuiteStatuses {
  estimate: NetsuiteStatus;
  invoiceVendorBill: NetsuiteStatus;
  salesOrder: NetsuiteStatus;
}

export interface DealRequest {
  _id: string;
  data: DealData;
  availableToRolesIds?: string[] | null;
}

export type AddDealRequest = AddRequest<DealRequest>;
export interface NetsuiteVendorBillId {
  vendorBillId: string;
  type: "gap" | "service warranty" | "dealer";
}

export enum DealSourceTypes {
  Dealership_Application = "dealership_application",
  Mobile_Application = "mobile_application",
  Online_Application = "online_application",
  Web_Finance_Direct = "web_finance_direct",
  AI_Parser = "AI-Parser"
}
export interface DealSource {
  type: DealSourceTypes;
  applicationId?: string;
}
export interface DealData {
  info: Info;
  source?: DealSource;
  applicant?: Applicant | null;
  coApplicant: Applicant | null;
  applicantId?: string | null;
  titleIssueId?: string | null;
  coApplicantId: string;
  lender?: Lender | null;
  payoffBank?: PayoffBank | null;
  stateTax?: State | null;
  user?: User | null;
  userId: string;
  dealership?: Dealership | null;
  vehicleInsuranceCompany: VehicleInsuranceCompany | null;
  notes?: Note[];
  dealerNotes?: DealerNote[];
  stipulations?: Stipulation[];
  appOneApplicationNumber: string;
  appOneApplicationURL: string;
  dealertrackDealNumbers: DealertrackDealNumbers;
  dealershipId?: string | null;
  lenderId: string;
  appOneData: AppOneDeal | null;
  creditSmartsId?: string;
  creditSmartsDate?: string;
  creditBureaus: CreditBureau[];
  creditPrequalify?: CreditPrequalify[];
  netsuiteInvoiceId?: string;
  netsuiteEstimateId?: string;
  order?: number;
  netsuiteSalesOrderId?: string;
  netsuiteVendorBillIds?: NetsuiteVendorBillId[];
  netsuiteStatus: NetsuiteStatuses;
  oneSpanSignings?: OneSpanSigning[];
  printedDocuments: PrintedDocument[];
  managerPrograms?: ManagerProgram[];
  collaterals?: Collateral;
  showAllLenders?: boolean;
}

export interface Payment {
  type: "monthly" | "yearly";
  numberOfPayments: number;
  daysToFirstPayment: number;
  interestRate: number;
  dealTotal: number;
  downPayment: number;
  amountFinanced: number;
  totalAftermarket: number;
  totalTaxes: number;
  localTaxes: number;
  stateTaxes: number;
  taxableAmount: number;
  totalFees: number;
  monthlyPayment: number;
  totalOfPayments: number;
  totalSalePrice: number;
  fundedAmount: number;
  buyPercent: number;
  dealershipSplit?: number;
  wfdSplit?: number;
  dealershipGAPSplit?: number;
  wfdGAPSplit?: number;
  dealershipWarrantySplit?: number;
  wfdWarrantySplit?: number;
  splitMethod: RelationTypes;

  correspondentPercent?: number;
  reserve?: number;
  reserveMethod?: ReserveMethod;
  maxReserve?: number;
  markupData?: {
    markup: number;
    reservePercentage: number;
    isDefault?: boolean;
    dealerSplitPercentage?: number;
    extraReserveFixed?: number;
    extraReservePercentage?: number;
  };
}

export interface Profit {
  totalServiceWarrantyProfit?: number;
  totalGAPProfit?: number;
  grossProfit?: number;
  totalExtraProfit?: number;
  grossProfitWithExtra?: number;
  dealershipProfit?: DealershipProfit;
  grossRevenue?: number;
  productGrossRevenue?: number;
  wfdProfit?: WfdProfit;
  representativeProfit?: RepresentativeProfit;
  managerProfit?: ManagerProfit;
}

export interface DealershipProfit {
  reserveCommission?: number;
  splitFromDeal?: number;
  splitTotalFromServiceWarranty?: number;
  splitTotalFromGap?: number;
  totalProfit?: number;
  totalProfitFees?: number;
  totalDealProfit?: number;
  totalProfitWFD?: string;
}
export enum ReserveMethod {
  fixed = "fixed",
  percentage = "percentage",
  "difference between buy / sell rate * total amount financed then / sell rate and then * reserve %" = "difference between buy / sell rate * total amount financed then / sell rate and then * reserve %",
  markup = "markup",
  "markup foursight" = "markup foursight",
  "min(<interest>/2,2.25)" = "min(<interest>/2,2.25)"
}

export interface WfdProfit {
  totalReserveProfit?: number;
  totalProductsProfit?: number;
  reserveCommission?: number;
  splitFromDeal?: number;
  splitTotalFromServiceWarranty?: number;
  splitTotalFromGap?: number;
  totalProfit?: number;
  splitFromVehicleCoverage?: number;
  extraGAPProfit?: number;
  extraServiceWarrantyProfit?: number;
  reserveMethodType?: ReserveMethod;
  extraReserveProfit?: number;
  reserverPercentage?: number;
}

interface RepresentativeProfit {
  commission: number;
}

export interface ManagerProfit {
  commission: number;
  productCommission?: number;
  reserveCommission?: number;
  commissionableAmount: number;
  chargebackPercentReserve?: number;
  chargebackPercentProduct?: number;
  gapCommission?: number;
  ascCommission?: number;
}
export type AllInsurancesTypes =
  // | ProtectiveInsurance
  ExpressInsurance | ASCInsurance | CustomInsurance;
export type Insurances = AllInsurancesTypes[] | undefined;
interface AftermarketOptions {
  totalGAPRemitPrice: number;
  totalGAPDealerCostPrice: number;
  totalGAPSellPrice: number;
  totalServiceWarrantyRemitPrice: number;
  totalServiceWarrantyDealerCostPrice: number;
  totalServiceWarrantySellPrice: number;
  totalPaintAndFabricationPrice: number;
  totalPrePaidMaintenancePrice: number;
  totalWheelAndTirePrice: number;
  totalEcoPrice: number;

  insurances?: Insurances;
}
export const defaultDaysToFirstPayment = 30;

export const additionalInsuranceTypes = [
  "paint and fabrication",
  "pre-paid maintenance",
  "eco",
  "wheel and tire"
] as const;

export const insuranceTypes = ["gap", "service warranty"] as const;

export type InsuranceType = typeof insuranceTypes[number];

export type InsuranceSoldBy = "Dealer" | "WFD";
export interface ExpressInsurance {
  id: string;
  type: "F&I Express";
  provider: string;
  originalRate: any;
  chosenRate: {
    Coverage: string;
    EX1ProviderID: string;
    EX1RateResponseID: string;
    ContractNumber: string;
    ContractForm?: string; // PDF
    months: number;
    insuranceType: InsuranceType;
    dynamicSurcharges: DynamicSurcharge[];
    dealerCostPrice: number;
    retailPrice: number;
    remitPrice: number;
    profit: number;
    product: Product;
    soldBy?: InsuranceSoldBy;
  };
}
export const allInsuranceTypes = [...insuranceTypes, ...additionalInsuranceTypes] as const;
export type AllInsuranceTypes = typeof allInsuranceTypes[number];

export interface CustomInsurance {
  type: "Custom";
  provider: string;
  customProvider?: string;
  url?: string;
  id: string;
  fileName?: string;
  chosenRate: {
    customMonths?: number;
    customMonthsEnabled?: boolean;
    months: number;
    insuranceType?: AllInsuranceTypes;
    dealerCostPrice: number;
    retailPrice: number;
    remitPrice: number;
    profit: number;
    deductible?: number;
    soldBy?: InsuranceSoldBy;
  };
  sequence?: Sequence;
}

export interface TaxesAndFees {
  isFixedTax: boolean;
  titleTransfer: number;
  license: number;
  documentRegistrationFees: number;
  filingFees: number;
  otherFees: number;
  fundingFees?: FundingFee[];
  totalMembershipFees: number;
  totalUCCFees: number;
  wfdMinimumFee: number;
  acquisitionFee: number;
  lenderAcquisitionFee: number;
  detailed: {
    registrationTax: number;
    plateFee: number;
    tempPlateFee: number;
    wheelageTax: number;
    psVehicleFee: number;
    transferTax: number;
    titleTransferFee: number;
    techSurchargeFee: number;
    lienFee: number;
    deputyFilingFee: number;
    exciseTax: number;
    licenseAndRegFee: number;
    processingFee: number;
    contributionFee: number;
  };
}

export interface FundingFee {
  amount: number;
  description: string;
  type: string;
  document: {
    name: string;
    url: string;
    metadata: { contentType: string };
  };
}
export interface TradeIn {
  value?: number;
  year: number;
  model: string;
  odometer?: number;
  make: string;
  VIN?: string;
}
export interface Price {
  price: number;
  options: number;
  rebates: number;
  totalCash: number;
  trade: TradeIn[] | undefined;
  totalTrade?: number;
  payoff: number;
  netTrade: number;
}

export interface Vehicle {
  unitStatus?: string;
  invoice?: string;
  unitType?: string;
  VIN: string;
  isVinDecoded?: boolean;
  year: number;
  make: string;
  model: string;
  odometer: number;
  MSRP?: number;
  boat?: Boat;
  hasTrailer?: boolean;
  trailer?: Trailer;
  land: {
    year: number;
    make: string;
    model: string;
    VIN: string;
    unitType: string;
  };
  powerSport?: {
    cc?: string;
  };
}
export interface DateAudit {
  type: "submittedForFunding";
  oldDate?: string;
  newDate: string;
  updatedAt: string;
  updatedBy: string;
}
export interface DealDates {
  contractDate?: string;
  fundedAt?: string;
  payrollDate?: string;
  firstPaymentDate: string;
  submittedForFunding: string;
}

export enum DealStatus {
  Lead = "lead",
  CreditCheck = "credit check",
  WaitingForDealDetails = "waiting for deal details",
  DealCreated = "deal created",
  PendingLenderDecision = "pending lender decision",
  Approved = "approved",
  Countered = "countered",
  SentToDealer = "sent to dealer",
  SubmittedForFunding = "submitted for funding",
  FundingHeld = "funding held",
  DidNotProcess = "did not process",
  Funded = "funded",
  Cancelled = "cancelled",
  Denied = "denied",
  Dead = "dead"
}

export type InfoWithoutTaxesAndFees = Omit<Info, "taxesAndFees">;

export interface CreditScore {
  type: CreditCheckTypes;
  scores: { applicant: string | null; coApplicant: string | null };
  url: string;
  applicantName: string;
  coApplicantName: string;
  applicantId: null | string;
  coApplicantId: null | string;
  isDuplicate: boolean;
  duplicateInfo: string;
}
export const collateralTypes = [
  "Automotive",
  "Marine",
  "Recreational Vehicles",
  "Power Sport"
] as const;

export type CollateralTypes = typeof collateralTypes[number];

export enum ReasonsDidNotProcess {
  OutOfLendingArea = "Out of lending area",
  InsufficientCollateral = "Insufficient collateral",
  InsufficientCredit = "Insufficient credit/No subprime",
  ITINSSCard = "ITIN / SS Card for Work Auth Only"
}
export enum ReasonsDead {
  NeedCosigner = "Need cosigner",
  Fraudulent = "Fraudulent",
  WentWithDifferentLender = "Went with different lender",
  CannotAffordPayment = "Cannot afford payment",
  VehicleDidNotMeetExpectations = "Vehicle did not meet expectations",
  CannotComeUpWithAdditionalCashDown = "Cannot come up with additional cash down",
  NoLongerInterestedInPurchasing = "No longer interested in purchasing",
  ProofOfIncomeDiDNotCheckOut = "Proof of income did not check out",
  ITINSSCard = "ITIN / SS Card for Work Auth Only"
}
export type StatusReason = {
  status:
    | DealStatus.DidNotProcess
    | DealStatus.Dead
    | DealStatus.Approved
    | DealStatus.Countered
    | DealStatus.SentToDealer;
  date: string;
  marked: boolean;
  reason: ReasonsDidNotProcess | ReasonsDead;
  userId: string;
};

export type StatusReasonApplicant = {
  status:
    | DealStatus.DidNotProcess
    | DealStatus.Dead
    | DealStatus.Approved
    | DealStatus.Countered
    | DealStatus.SentToDealer;
  date: string;
  reason: string;
  stillConsidering: boolean;
  marked: boolean;
};
export type StatusReasons = {
  manager: StatusReason;
  dealership: StatusReason;
  applicant: StatusReasonApplicant;
};

export interface Info {
  prediction?: Prediction;
  lenderPrediction?: LenderPrediction;
  extractedCreditScores?: {
    applicant: {
      score: number;
    };
    coApplicant: {
      score: number;
    };
  };
  totalIncomes?: {
    applicant: {
      monthly: number;
      annual: number;
    };
    coApplicant: {
      monthly: number;
      annual: number;
    };
  };
  lastStatusHistory?: {
    status: DealStatus;
    reason: string;
    date: string;
    userEmail: string;
  };
  statusReasons?: StatusReasons;
  adfEmailSent?: boolean;
  wasInStatus?: Record<DealStatus, number>;
  isInStatus?: Record<DealStatus, number>;
  status: DealStatus;
  statusHistory?: {
    status: DealStatus;
    reason: string;
    date: string;
    userEmail: string;
  }[];
  collateralReceivedDate: string | null;
  creditCheckDate: string | null;
  documentsHistory?: DocumentsHistory[];
  fundingPaymentMethod: "ACH" | "Check";
  type: CollateralTypes;
  emailInfo: EmailData;
  dateAudits?: DateAudit[];
  refNumber: string;
  driverlicense: string;
  vehicle: Vehicle;
  user: User | null;
  price: Price;
  payment: Payment;
  profit?: Profit;
  accounting?: Accounting;
  vehicleCoverage: VehicleCoverage;
  aftermarketOptions: AftermarketOptions;
  taxesAndFees: TaxesAndFees;
  dealDates: DealDates;
  files: File[];
  creditScores: CreditScore[];
  assignedDealershipEmails: string[];
}

export interface Customization {
  customization: string;
}

export interface Driver {
  firstName: string;
  middleName: string;
  lastName: string;
  driverLicenseNumber: string;
  familyRelation: string;
  birthDate: Date | null;
  homePhone: string;
  mobilePhone: string;
  businessPhone: string;
  email: string;
  propertyAddress: string;
  propertyOwnership: string;
}

export interface VehicleCoverage {
  amountOfPremium: number;
  commissionPercent: number;
}

export interface Boat {
  type: string;
  length: number;
  engineType: string;
  numberOfEngines: number;
  engine: Engine;
  secondEngine: Engine;
  hull: Hull;
  horsepower: number;
  cc: number;
  maxSpeed: number;
  enginesValue: number;
}

export interface Engine {
  engineNumber: string;
  unitStatus: "new" | "used";
  engineNumbers: string;
  year: number;
  make: string;
  model: string;
}

export interface Trailer {
  year: number;
  make: string;
  model: string;
  unitStatus: "new" | "used";
  serialNumber: number;
}

export interface Hull {
  hullNumber: number;
  year: number;
  make: string;
  model: string;
  hullMaterial: string;
  hullValue: string;
}

interface EmailData {
  totalValueOfUnit: number;
  applicant: boolean;
  coApplicant: boolean;
}

export interface SendEmailData {
  data: {
    to: string[];
    subject: string;
  } & ({ templateName: string; templateData: DealData } | { body: string });
}

export interface Accounting {
  copied?: boolean;
  fundingNoticeDate?: string;
  collateralFundingDate?: string;
  reserveFundingDate?: string;
  warrantyPrice?: number;
  warrantyCost?: number;
  gapPrice?: number;
  ecoPrice?: number;
  prePaidMaintenancePrice?: number;
  wheelAndTirePrice?: number;
  paintAndFabricationPrice?: number;
  gapRemit?: number;
  warrantyRemit?: number;
  gapCost?: number;
  profit?: Profit;
  payment?: Payment;
  taxesAndFees?: TaxesAndFees;
}

export interface NetsuiteTotals {
  invoiceTotal: number;
  warrantyBillTotal: number;
  gapBillTotal: number;
  dealerBillTotal: number;
}

interface DocumentsHistory {
  printedAt: string;
  userEmail: string;
  documents: { packType: PackType; url: string }[];
}
export const isAsc = (insurance: AllInsurancesTypes): insurance is ASCInsurance =>
  insurance.type === "ASC";
export const isFIExpress = (insurance: AllInsurancesTypes): insurance is ExpressInsurance =>
  insurance.type === "F&I Express";
// export const isProtective = (insurance: AllInsurancesTypes): insurance is ProtectiveInsurance =>
//   insurance.type === "Protective";
export const isCustom = (insurance: AllInsurancesTypes): insurance is CustomInsurance =>
  insurance.type === "Custom";
