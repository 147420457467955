import { useQuery } from "@tanstack/react-query";
import { HttpQuery } from "Chat/types/http";
import { getDealershipUsers, getDealershipUsersForReps } from "Chat/http/dealership_users";

const useDealershipUsers = (query?: HttpQuery) => {
  const queryKey = ["dealership-user", query ?? []];

  const {
    data: dealershipUsers,
    isLoading: dealershipUsersAreLoading,
    error: dealershipUsersError
  } = useQuery({
    queryKey,
    queryFn: getDealershipUsers(query),
    enabled: query !== undefined
  });

  return {
    data: dealershipUsers,
    isLoading: dealershipUsersAreLoading,
    error: dealershipUsersError
  };
};

export const useDealershipUsersForReps = (query?: HttpQuery) => {
  const queryKey = ["dealership-user-reps", query];

  const {
    data: dealershipUsers,
    isLoading: dealershipUsersAreLoading,
    isFetching,
    error: dealershipUsersError
  } = useQuery({
    queryKey,
    queryFn: getDealershipUsersForReps(query),
    enabled:
      (typeof query?.query?.from === "string" && typeof query?.query?.to === "string") ||
      (query?.query?.from === undefined && query?.query?.to === undefined)
  });

  return {
    data: dealershipUsers,
    isLoading: dealershipUsersAreLoading,
    isFetching,
    error: dealershipUsersError
  };
};

export default useDealershipUsers;
